@charset "UTF-8";
// ============================
// 読み込み
// ============================
@import '../organisms/filter/filter-parts';
@import "./filter-seo-links";

// ============================
// ページ固有
// ============================

// Shiborikomi design page
// mixinは../organisms/filter/filterに

.t-filter {
    position: fixed;
    visibility: hidden;
    top: 0;
    right: -100%;
    z-index: $filter-z-index;
    width: 100%;
    height: calc(100% + 100px); // android chrome用の裾を足す
    background: rgba(0, 0, 0, 0.6);
    transition: all .2s;

    .t-filter-wrap {
        height: calc(100% - 100px); // 足した裾を戻す
        padding: 8px;

        // contentトップの目隠し用
        &::before {
            content: "";
            position: absolute;
            top: 8px;
            left: 8px;
            z-index: $filter-content-top-z-index;
            @include content-box-width;
            height: 9px;
            border-radius: 4px 4px 0 0;
            border: 1px solid $border-color;
            border-bottom: none;
            background: #fff;
        }
    }

    // 「もっと見る」「閉じる」ボタン下の余白調整
    .a-read-more-btn-s, .a-fold-btn {
        margin: 0 0 12px 0;
    }
}

// 絞り込みのモーダルを開いたとき
.t-filter--on {
    visibility: visible;
    position: fixed;
    right: 0;
    // 絞り込み条件を保存・再検索
    .o-filter-re-search-box {
        visibility: visible;
        display: block;
        right: 62px;
    }
}
