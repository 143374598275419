@charset "UTF-8";
@import "../atoms/z-index";
@import "../atoms/colors";

// 丸いラジオボタン
.m-radio-btn {
    display: block;

    .a-radio-btn__txt {
        display: block;
        position: relative;
        width: 100%;
        padding: 8px 0 8px 30px;
        box-sizing: border-box;
        font-size: 13px;
        line-height: 22px;
        color: $base-link-color;
        // 外側の線
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: calc(50% - 10px);
            left: 0;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid $border-color;
            background: #fff;
        }

        // 内側の丸
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: calc(50% - 5px);
            left: 5px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: $background-color;
        }
    }

    // チェックされた時
    .a-radio-btn__input {
        display: none;

        &:checked + .a-radio-btn__txt {
            padding: 8px 0 8px 30px;
            font-weight: 700;
            color: $active-text-color;

            &::before {
                border: 2px solid $active-color;
                left: 0;
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                top: calc(50% - 5px);
                left: 5px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: $active-color;
            }
        }
    }
}