@charset "UTF-8";

@import "../../atoms/z-index";
@import "../../atoms/buttons/header-buttons";

// SP共通レイアウトヘッダー
/* ===============================================
/*  HEADER
------------------------------------------------*/
.o-header-member {
    position: relative;
    height: 50px;
    padding: 4px 8px;
    background: #fff;
    border-bottom: 2px solid #1d3850;
}

.o-header-member__inner {
    position: relative;
    max-width: 1024px;
    margin: 0 auto;
    overflow: hidden;
}

.o-header-member__logo {
    float: left;

    &:hover {
        @include mq-up('md') {
            @include hover;
        }
    }
}


.o-header-member__btns-ja,.o-header-member__btns-en{
    float: right;
    li {
        float: left;
        margin-left: 4px;
    }
}


.languageHidden {
    display: none !important;
}
