// 見出し要素用
@import "colors";

.a-heading-ttl {
    margin: 0;
    padding: 0 8px 0 14px;
    font-size: 1.4rem;
    font-weight: 700;
    display: block;
    background: #fff;
    position: relative;
    line-height: 1.7;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 2px;
        width: 4px;
        height: 100%;
        border-left: 4px solid $base-color;
        vertical-align: middle;
    }
}

