@charset "utf-8";
// タテスクボタン

// ============================
// 読み込み
// ============================
@import '../arrow-mixin';
@import "../colors";
@import "../z-index";
@import "../ttsk/ttsk-icon";
@import "../../templates/layout/mixin";
@import "../../templates/layout/ttsk_mixin";
@import "../../templates/layout/font-variable";

// ==============================================
// スタイル
// ==============================================
// 標準ボタンのベース
@mixin ttsk-btn-base {
    display: block;
    position: relative;
    width: 100%;
    border-radius: 2px;
    box-sizing: border-box;
    font-weight: $font-bold;
    line-height: 1.2;
    text-align: center;
    @include baseTransition;
}

// 共通で付与するclass マルチクラス
.a-ttsk-btn {
    max-width: 600px;
    cursor: pointer;
    margin: 0 auto;
    @include baseTransition;
    &:hover {
        @include mq-up('md'){
            opacity: 1;
        }
    }
}

// プライマリーボタンのベース
@mixin ttsk-primary-btn-base {
    background-color: $ttsk-blue-color;
    color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    &:hover {
        @include mq-up('md'){
            background-color: #0092cc;
        }
    }
}

// プライマリーボタン
.a-ttsk-btn--primary {
    @include ttsk-btn-base;
    @include ttsk-primary-btn-base;
    font-size: 14px;
    padding: 15px 0;
    @include mq-up('md'){
        font-size: 16px;
        padding: 16px 0;
    }
    // disabledの場合（aタグの場合は.a-ttsk-btn--disabledを付与）
    &:disabled,
    &.a-ttsk-btn--disabled {
        @include disabledColor;
        box-shadow: none;
        pointer-events: none;
    }
}

// プライマリーボタン（large）
// span内は第〇話 SP改行
.a-ttsk-btn--primary-large {
    @include ttsk-btn-base;
    @include ttsk-primary-btn-base;
    font-size: 14px;
    padding: 15px 10px;
    @include mq-up('md'){
        font-size: 16px;
        padding: 16px 12px;
    }
    span {
        display: block;
        font-size: 12px;
        font-weight: normal;
        padding: 0;
        margin-top: 4px;
        @include mq-up('md'){
            display: inline-block;
            font-size: 14px;
            padding-left: 6px;
        }
    }
    // disabledの場合（aタグの場合は.a-ttsk-btn--disabledを追加）
    &:disabled,
    &.a-ttsk-btn--disabled {
        @include disabledColor;
        box-shadow: none;
        pointer-events: none;
    }
}

// プライマリーボタン（small）
.a-ttsk-btn--primary-small {
    @include ttsk-btn-base;
    @include ttsk-primary-btn-base;
    width: auto;
    font-size: 16px;
    padding: 10px;
    // disabledの場合（aタグの場合は.a-ttsk-btn--disabledを追加）
    &:disabled,
    &.a-ttsk-btn--disabled-small {
        @include disabledColor;
        box-shadow: none;
        pointer-events: none;
    }
}

// セカンダリーボタンのベース
@mixin ttsk-secondary-btn-base {
    display: block;
    position: relative;
    width: 100%;
    border: 1px solid $ttsk-light-blue-color;
    border-radius: 2px;
    box-sizing: border-box;
    color: $ttsk-dark-blue-color;
    font-weight: $font-bold;
    line-height: 1.2;
    text-align: center;
    &:hover {
        @include mq-up('md') {
            background-color: #e4f6fd;
        }
    }
}

// セカンダリーボタン
.a-ttsk-btn--secondary {
    @include ttsk-secondary-btn-base;
    font-size: 14px;
    padding: 13px 0;
    @include mq-up('md'){
        font-size: 16px;
        padding: 15px 0;
    }
    // disabledの場合（aタグの場合は.a-ttsk-btn--disabledを追加）
    &:disabled,
    &.a-ttsk-btn--disabled {
        @include disabledColor;
        box-shadow: none;
        border: 1px solid #ccc;
        pointer-events: none;
        padding: 15px 0;
        pointer-events: none;
    }
}

// セカンダリーボタン(mini)
.a-ttsk-btn--secondary-mini {
    @include ttsk-secondary-btn-base;
    font-size: 12px;
    padding: 4px 17px;
    @include mq-up('md'){
        font-size: 14px;
        padding: 3px 15px;
    }
    // disabledの場合（aタグの場合は.a-ttsk-btn--disabledを追加）
    &:disabled,
    &.a-ttsk-btn--disabled {
        @include disabledColor;
        box-shadow: none;
        border: 1px solid #ccc;
        pointer-events: none;
    }
}

// disableボタン
.a-ttsk-btn--disabled {
    @include ttsk-btn-base;
    @include disabledColor;
    font-size: 14px;
    padding: 15px 0;
    pointer-events: none;
    @include mq-up('md'){
        font-size: 16px;
        padding: 16px 0;
    }
}

// disableボタン（small）
.a-ttsk-btn--disabled-small {
    @include ttsk-btn-base;
    @include disabledColor;
    width: auto;
    font-size: 16px;
    padding: 10px;
    pointer-events: none;
}


// cancelボタン
.a-ttsk-btn--cancel {
    @include ttsk-btn-base;
    color: $ttsk-dark-blue-color;
    background-color: #f2f2f2;
    font-weight: normal;
    font-size: 14px;
    padding: 15px 0;
    @include mq-up('md'){
        font-size: 16px;
        padding: 16px 0;
    }
    &:hover {
        @include mq-up('md'){
            background-color: #ddd;
        }
    }
}

// タテスクアローボタンのベース
// ==============================================
@mixin ttsk-btn-arrow-base {
    @include ttsk-secondary-btn-base;
    font-size: 14px;
    padding: 13px 0;
    @include mq-up('md'){
        font-size: 16px;
        padding: 15px 0;
    }
    &::after {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        //right: 20px;
        transform: translateY(-50%);
    }
    &:hover {
        @include mq-up('md'){
            background-color: #e4f6fd;
        }
    }
}

// arrow下向きボタン
.a-ttsk-btn--arrow-down {
    @include ttsk-btn-arrow-base;
    &::after {
        @include down-arrow($color: $ttsk-blue-color);
        width: 9px;
        height: 9px;
        top: 48%;
        right: 20px;
        transform: translateY(-50%) rotate(315deg);
        transition: 0.3s;
        @include mq-up('md'){
            width: 10px;
            height: 10px;
        }
    }
    // disabledの場合（aタグの場合は.a-ttsk-btn--disabledを追加）
    &:disabled,
    &.a-ttsk-btn--disabled {
        @include disabledColor;
        box-shadow: none;
        border: 1px solid #ccc;
        pointer-events: none;
        &::after {
            content: none;
        }
    }
}

// arrow右向きボタン
.a-ttsk-btn--arrow-right {
    @include ttsk-btn-arrow-base;
    &::after {
        @include right-arrow($color: $ttsk-blue-color);
        width: 9px;
        height: 9px;
        top: 48%;
        right: 18px;
        transition: 0.3s;
        @include mq-up('md'){
            width: 10px;
            height: 10px;
        }
    }
    // disabledの場合（aタグの場合は.a-ttsk-btn--disabledを追加）
    &:disabled,
    &.a-ttsk-btn--disabled {
        @include disabledColor;
        box-shadow: none;
        border: 1px solid #ccc;
        pointer-events: none;
        &::after {
            content: none;
        }
    }
}

// シリーズ予約ボタン
// ==============================================
// シリーズ予約ボタンのベース
@mixin ttsk-btn-reserve-base {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 46.8px;
    font-size: 14px;
    padding: 14px 0;
    @include mq-up('md'){
        max-height: none;
        padding: 16px 0;
    }
}

// シリーズ予約購入ボタン
.a-ttsk-btn--reserve {
    @include ttsk-secondary-btn-base;
    @include ttsk-btn-reserve-base;

    .ico {
        display: inline-block;
        padding-right: 5px;
        font-size: 16px;
        vertical-align: middle;
    }
}

// シリーズ予約済みボタン
.a-ttsk-btn--reserve-rev {
    @extend .a-ttsk-btn--cancel;
    @include ttsk-btn-reserve-base;

    .a-ttsk-icon--check {
        padding-right: 5px;
    }
}

// お気に入り☆ボタン
// ==============================================
.a-ttsk-favorite-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 42px;
    color: $ttsk-blue-color;
    font-size: 22px;
    line-height: 1;
    margin: 0;
    transition: none;

    @include mq-up('md'){
        font-size: 26px;
        width: 44px;
        height: 40px;
    }

    &:before {
        font-family: Material Icons, sans-serif;
        content: "\e83a";
    }

    // アクティブ
    &.a-ttsk-favorite-btn--active {
        color: $ttsk-yellow-color;

        &:before {
            font-family: Material Icons, sans-serif;
            content: "\e838";
        }
    }

    // アクティブ アニメーション付き
    &.a-ttsk-favorite-btn--active-animation {
        @extend .a-ttsk-favorite-btn--active;

        animation: icon-button-active-animation 0.2s;
    }

    // 非アクティブ アニメーション付き
    &.a-ttsk-favorite-btn--inactive-animation {
        animation: icon-button-inactive-animation 0.2s;
    }

    &:hover {
        opacity: 1;
    }
}

// 通知ボタン（ベル）
// ==============================================
.a-ttsk-notification-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 42px;
    transition: none;

    @include mq-up('md'){
        width: 44px;
        height: 40px;
    }

    &:before {
        content: "";
        display: block;
        width: 22px;
        height: 22px;
        background: url("/anne/img/ttsk/icon-notification.svg") center center no-repeat;
        background-size: contain;

        @include mq-up('md'){
            width: 26px;
            height: 26px;
        }
    }

    // アクティブ
    &.a-ttsk-notification-btn--active {
        &:before {
            background-image: url("/anne/img/ttsk/icon-notification-active.svg");
        }
    }

    // アクティブ アニメーション付き
    &.a-ttsk-notification-btn--active-animation {
        @extend .a-ttsk-notification-btn--active;

        animation: icon-button-active-animation 0.2s;
    }

    // 非アクティブ
    &.a-ttsk-notification-btn--inactive {
        &:before {
            background-image: url("/anne/img/ttsk/icon-notification-inactive.svg");
        }
    }

    // 非アクティブ アニメーション付き
    &.a-ttsk-notification-btn--inactive-animation {
        @extend .a-ttsk-notification-btn--inactive;

        animation: icon-button-inactive-animation 0.2s;
    }
}

// アイコンボタン用アニメーション
// ==============================================
// アクティブ時
@keyframes icon-button-active-animation {
    0%  {
        transform: scale(1,1);
    }
    50% {
        transform: scale(0.6, 0.6);
    }
    100% {
        transform: scale(1,1);
    }
}

// 非アクティブ時
@keyframes icon-button-inactive-animation {
    0%  {
        transform: scale(1,1);
    }
    50% {
        transform: scale(0.6, 0.6);
    }
    100% {
        transform: scale(1,1);
    }
}