@charset "utf-8";
// チェックアイコンリスト

// ==============================================
// 読み込み
// ==============================================
@import "../../atoms/colors";

// ==============================================
// スタイル
// ==============================================
.m-ttsk-check-list {
    margin-left: 15px;

    li {
        position: relative;
        font-size: 11px;
        text-align: left;

        &::before {
            content: "\e5ca";
            display: inline-block;
            position: absolute;
            top: 0;
            left: -15px;
            color: $ttsk-green-color;
            font-family: 'Material Icons';
            font-size: 12px;
        }

        &:not(:first-child) {
            margin-top: 6px;
        }
    }
}