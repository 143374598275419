@charset "utf-8";
// リンク等で使用するarrowアイコンのmixin

// ============================
// 読み込み
// ============================
@import "anne/scss/sp/atoms/colors";
@import "../../templates/layout/ttsk_mixin";

// ==============================================
// スタイル
// ==============================================
// 疑似要素（before,after）内でinclude
// 例：@include ttsk-icon--arrow-right($ttsk-gray-color);　()内にアイコンの色を指定

// arrow right
@mixin ttsk-icon-arrow-right($color: $arrow-color) {
    content: '\e409';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: $color;
    font-family: 'Material Icons Outlined';
    font-size: 16px;
    font-weight: normal;
    @include mq-up('md'){
        font-size: 18px;
    }
}

// arrow down
@mixin ttsk-icon-arrow-down($color: $arrow-color) {
    content: '\e313';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: $color;
    font-family: 'Material Icons Outlined';
    font-size: 16px;
    font-weight: normal;
    @include mq-up('md'){
        font-size: 18px;
    }
}
