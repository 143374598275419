@charset "UTF-8";
// SP Anne全体のテンプレートの骨格となるlayoutのSCSS
@import "reset";
@import "base";
@import "../../atoms/colors";
@import "../../atoms/misc";
@import "../../atoms/text";
@import "../../atoms/loading-animation";
@import "../../atoms/done-popup";
@import "../../organisms/layout/footer";
@import "../../organisms/layout/footer-member";
@import "../../organisms/layout/header";
@import "../../organisms/layout/header-member";
@import "../../organisms/layout/store-navigation";
@import "../../organisms/layout/breadcrumb";
@import "../../../../../../asset/louis/pc/scss/jquery-ui.custom";
@import "../../../../../../asset/bob/scss/sp/module/common/coupon-nav";
@import "stack-pickup";
//@import "../../organisms/layout/footer-beginner-guide";
@import "../../organisms/layout/footer-guide";
@import "../filter";

// footer
footer {
    border-top: 2px solid $base-color;
    margin-top: 16px;
}

.t-whole {
    // メニュー開いているときにonが付く
    &.t-whole--on {
        overflow: hidden;
    }
}

.t-content {
    margin-top: 94px; // ヘッダの領域分だけ下にさげる
}

// Chrome系では、bodyにoverflow:hiddenつけることで、モーダルが出ている最中に
// 裏側のスクロールを禁止できる。ただし、iOS系では無効
.t-body--modal-back {
    overflow: hidden;
}

// iOS系は中身のheightを100vhにしてしまうことで、scrollを擬似的に無効にする
.t-body--modal-back .t-whole {
    overflow: hidden;
    height: 100vh;
    max-height: 100vh;
}
