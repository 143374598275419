@charset "UTF-8";
@import "../atoms/z-index";
@import "../atoms/colors";

.m-square-check-box--only {
    // グレーの外枠
    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 22px;
        height: 22px;
        border: 2px solid $border-color;
        background: #fff;
    }

    // グレーのチェック
    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 3px;
        left: 7px;
        width: 8px;
        height: 12px;
        border-right: 2px solid $background-color;
        border-bottom: 2px solid $background-color;
        transform: rotate(45deg);
    }
}

// 四角いチェックボックスボタン・テキスト付き
.m-square-check-box {
    display: block;
    padding: 8px 0;

    // テキスト部分
    .a-square-check-box__txt {
        display: inline-block;
        position: relative;
        padding: 0 0 0 32px;
        font-size: 13px;
        line-height: 22px;
        color: $base-link-color;
        // グレーの外枠
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 22px;
            height: 22px;
            border: 2px solid $border-color;
            background: #fff;
            color-scheme: only light;
        }

        // グレーのチェック
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 3px;
            left: 7px;
            width: 8px;
            height: 12px;
            border-right: 2px solid $background-color;
            border-bottom: 2px solid $background-color;
            transform: rotate(45deg);
            color-scheme: only light;
        }
    }

    // チェックされた時
    .a-square-check-box__input {
        display: none;

        &:checked + .a-square-check-box__txt {
            font-weight: 700;
            color: $active-text-color;

            &::before {
                background: $active-color;
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 3px;
                left: 7px;
                width: 8px;
                height: 12px;
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
                transform: rotate(45deg);
            }
        }
    }
}

// 四角いチェックボックスボタン(チェックボックス横のテキストが複数行)
// 絞り込みの条件保存部分用
.m-square-check-box--multi-line {
    @extend .m-square-check-box;

    .a-square-check-box__txt {
        padding: 0 0 0 27px;
        line-height: 14px;

        .a-square-check-box__caution {
            display: block;
            font-size: 9px;
        }
    }
}