@charset "utf-8";
// タテスクテキスト

// ==============================================
// 読み込み
// ==============================================
@import "../colors";
@import "../../templates/layout/mixin";
@import "../../templates/layout/ttsk_mixin";

// ==============================================
// スタイル
// ==============================================
.a-ttsk__text--error {
	background-color: #fdede4;
	border: 1px solid $ttsk-red-color;
	color: $ttsk-red-color;
	font-size: 10px;
	font-weight: bold;
	line-height: 1.5;
	text-align: center;
	padding: 4px;
	@include mq-up('md'){
		font-size: 12px;
	}

	+.a-ttsk__text--error {
		margin-top: 10px;
	}
}

// 注釈（グレー）
.a-ttsk-text--annotation {
	font-size: 10px;
	color: $ttsk-light-text-color;
	@include mq-up('md'){
		font-size: 12px;
	}
}

// インデント
.a-ttsk-text--indent {
	text-indent: -1.3em;
	padding-left: 1.3em;
}
