@charset "utf-8";
// コイン・ポイントアイコン

// ==============================================
// 読み込み
// ==============================================
@import "../../templates/layout/ttsk_mixin";
@import "../../templates/layout/font-variable";
@import "../../atoms/colors";

// ==============================================
// スタイル
// ==============================================
// コイン数字、ポイント数字の組み合わせ
.m-ttsk-coin-item-base {
    display: flex;
    align-items: center;
    color: $ttsk-text-color;
    font-weight: $font-bold;
    word-break: keep-all;
}

.m-ttsk-coin-item,
.m-ttsk-point-item {
    @extend .m-ttsk-coin-item-base;
    font-size: 18px;

   .image-coin,
    .image-point {
        width: 16px;
        margin-right: 4px;
    }
}

// ヘッダー等で使う テキストカラーが異なる
.m-ttsk-coin-item--small-link,
.m-ttsk-point-item--small-link {
    @extend .m-ttsk-coin-item-base;
    color: $base-color;
    font-size: 12px;
    line-height: 1.2;

    .image-coin,
    .image-point {
        width: 13px;
        margin-right: 2px;
    }
}