@charset "utf-8";
// タテスク書影周り

// ==============================================
// 読み込み
// ==============================================
@import "../../atoms/colors";
@import "../../atoms/z-index";

// ==============================================
// スタイル
// ==============================================
.m-ttsk-thumb {
    .m-ttsk-thumb__image {
        display: block;
        position: relative;
        img{
            display: block;
            max-width: 100%;
            width: auto;
            max-height: 100%;
            vertical-align: middle;
            outline: 1px solid $ttsk-border-color;
        }
    }
    a {
        display: block;
    }
    .m-ttsk-thumb__label-area {
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        right: 0;
    }
}
