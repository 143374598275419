@charset "UTF-8";
// ============================
// 読み込み
// ============================
@import "../../atoms/text-link";
@import "../../templates/layout/font-variable";
@import '../../atoms/buttons/filtering-btn';
@import '../../atoms/buttons/toggle-btn-with-txt';
@import '../../molecules/radio-btn';
@import '../../molecules/square-check-box';
@import '../../organisms/calender';

// ============================
// 絞り込みメニューの中の要素
// ============================

// ↓もしタブメニューの幅を調整するような時が来たらここで変更？
// 横幅 - タブメニュー
@mixin content-width {
    width: calc(100% - 54px);
}

// 横幅 -タブメニュー54px-余白16px
@mixin content-box-width {
    width: calc(100% - 70px);
}

// 絞り込み条件を保存枠
.o-filter-re-search-box {
    visibility: hidden;
    position: fixed;
    right: -100%;
    bottom: 8px;
    z-index: $filter-save-box-z-index;
    @include content-box-width;
    padding: 8px 16px 8px;
    border-radius: 0 0 4px 4px;
    border: 1px solid $border-color;
    border-top: 1px solid $base-color;
    background: #fff;
    transition: visibility .2s, right .2s;
}

// 絞り込み画面がウィンドウの高さより短い時固定解除
.o-filter-re-search-box--release {
    position: relative;
    left: 0;
    bottom: 0;
    @include content-width;
}

// タブメニュー -------------------------------
.o-filter-nav {
    float: right;
    margin: 20px 0 0 0;

    .o-filter-nav-tab {

        .o-filter-nav-cell {
            display: block;
            position: relative;
            width: 54px;
            height: 44px;
            margin: 0 0 8px 0;
            background: #fff;
            border: 1px solid $border-color;
            border-left: none;
            border-radius: 0 4px 4px 0;
            text-align: center;

            .ico {
                margin: 0 0 2px 0;
                padding: 8px 0 0 0;
                font-size: 19px;
            }

            .ico-txt {
                font-family: $base-font-family !important;
                padding: 0 0 8px 0;
                font-size: 9px;
                font-weight: 700;
            }

            .o-filter-nav-cell-point {
                display: block;
                position: absolute;
                top: 2px;
                right: 2px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: $menu-badge-color;
            }

            // 閉じるボタンのみサイズ調整
            &:first-child {
                .ico {
                    padding: 6px 0 0 0;
                    font-size: 22px;
                }
            }
        }

        .o-filter-nav-cell--on {
            right: 2px;
            border: none;
            background: $base-color;

            .ico, .ico-txt {
                color: #fff;
            }
        }
    }
}

// 絞り込み内容（タブメニュー以外）---------------------------
.o-filter-content {
    @include content-width;
    max-height: 100%;
    overflow-y: scroll;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
    // TODO:検索条件を保存機能が実装されたらpaddingを8px 0 100pxに
    padding: 8px 0 64px;
    border-radius: 4px;
    border: 1px solid $border-color;
    border-bottom: none;
    box-sizing: border-box;
    background: #fff;
}

// 大項目を畳み切った時用の設定
.o-filter-content--shorten {
    padding: 8px 0 0;
    border-radius: 4px 4px 0 0;
}

.o-filter-box {
    clear: both;
    margin: 0 8px;

    // 大項目 見出し
    .o-filter-box-head {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: $filter-ttl-z-index;
        padding: 12px 0;
        border-top: 2px solid $base-color;
        border-bottom: 1px dotted $border-color;
        background: #fff;

        .a-filter-box-ttl {
            padding: 0 0 0 40px;
            font-weight: 700;
            color: $base-color;
        }
    }

    .o-filter-box-head--off {
        border-bottom: unset;
    }

    .o-filter-option-caution {
        margin-bottom: 20px;
    }
}

// 絞り込み条件詳細
.o-filter-option {
    display: block;
    margin: 0 8px;
    // 「選択中の条件を全て解除」ボタン
    .a-filter-release-all-btn {
        margin: 8px auto;
    }
}

.o-filter-setting {
    border-top: 1px solid $border-color;
    margin-top: 16px;
    padding-top: 8px;
}

// 閉じた時
.o-filter-option--off {
    display: none;
}

.o-filter-title {
    font-weight: 700;
}

// 小項目（保存済みの絞り込み条件・絞り込み で使用）
.o-filter-filtering-list {
    margin: 0 0 2px 0;

    > li {
        border-bottom: 1px dotted $border-color;

        &:last-child {
            border: none;
        }
    }
}

// 三角付きの見出し
.o-filter-filtering-list-ttl {
    display: block;
    position: relative;
    padding: 10px 0;
    font-weight: 700;
    color: $base-text-color;

    &::before {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        margin: 0 8px 0 4px;
        border-style: solid;
        border-width: 10px 6px 0 6px;
        border-color: $base-color transparent transparent transparent;
    }
}

.o-filter-filtering-list-ttl--on {
    @extend .o-filter-filtering-list-ttl;

    &::before {
        transform: rotate(180deg);
    }
}

// 小項目の中身
.o-filter-filtering-list-content {
    display: none;
}

.o-filter-filtering-list-content--on {
    display: block;

    li {
        margin: 4px 0;
    }

    > .a-close-btn-box {
        display: inline-block;
        position: relative;
        margin: 7px 0 0 0;
        vertical-align: middle;

        .a-close-btn {
            display: inline-block;
        }
    }
}

// 項目別 =====================================

// 選択中の絞り込み条件-------------------
.o-filter-option-list {
    display: flex;
    flex-wrap: wrap;
    margin: 8px;
    // padding: 0 0 8px 0;
    li {
        flex-shrink: 0;
        position: relative;
        margin: 4px 20px 4px 0;
        font-size: 13px;
        color: $weak-gray;
        // 個別削除・条件解除ボタン調節
        > .a-close-btn-box {
            display: inline-block;
            position: relative;
            padding: 0 4px 0 0;
            vertical-align: middle;

            .a-close-btn {
                display: inline-block;
            }
        }
    }
}

// 保存済みの絞り込み条件----------------
.o-filter-filtering-list-txt {
    display: inline-block;
    width: 90%;
    padding: 6px 4px;
    border-top: 1px dotted $border-color;
    font-size: 11px;
    vertical-align: top;
    color: $weak-gray;

    > li {
        display: inline;
        margin: 0;
    }
}

// 「この条件で再検索」ボタン 位置調整
.a-re-conditional-search-btn-box {
    margin: 0 0 10px 0;
}

// 表示設定------------------------------
.o-filter-setting-display {
    margin-bottom: 16px;
    li {
        overflow: hidden;
        margin: 8px 0 8px 0;
        font-size: 12px;

        .m-filter-toggle-txt {
            display: inline-block;
            float: left;
            height: 28px;
            line-height: 28px;
        }

        .a-toggle-btn {
            float: right;
            margin: 0 0 0 10px;
        }
    }
}

// 除外設定------------------------------
.o-filter-exclude-list {
    margin: 4px 0 0 4px;

    li {
        margin: 2px 0;

        &:last-child {
            margin: 0;
        }
    }
}

// 見出し:除外キーワード
.a-filter-box-sub-ttl {
    display: flex;
    align-items: center;
    margin: 12px 0 12px 8px;

    &::after {
        content: "";
        flex-grow: 1;
        margin: 0 0 0 5px;
        border-top: 1px dotted $border-color;
    }
}

// キーワード入力欄
.m-filter-keyword {
    margin: 0 auto;
    text-align: center;
}

.a-filter-keyword-box {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    // 絞り込みの横幅 - 設定ボタンの幅
    width: calc(100% - 100px);
    height: 38px;
    padding: 0 4px;
    margin: 0 4px 0 0;
    line-height: 38px;
    border-radius: 4px;
    border: 1px solid $border-color;
    vertical-align: middle;
    font-size: 11px;
}

// 並べ替え------------------------------------
.o-filter-sort-list {
    margin: 0 auto 12px;

    li {
        margin: 4px 0;
    }
}

// 絞り込み：価格
.o-filter-price-range {
    display: block;
    font-size: 12px;

    .o-filter-price-area {
        display: inline-block;
        width: 42%;
        vertical-align: middle;
    }

    .o-filter-price-area__input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: block;
        width: 100%;
        height: 40px;
        padding: 6px;
        border-radius: 4px;
        border: 1px solid $border-color;
    }
}

// 絞り込み＞見出しなし項目
.o-filter-filtering-list-no-ttl {
    margin: 10px 0 0 0;
}

// セーフサーチ用 注意文言
.o-filter-option-caution {
    clear: both;
    font-size: 10px;
    color: $base-text-color;

    .a-help-link {
        display: block;
        margin-bottom: 4px;
    }
}

// スイッチ（トグルボタン）
.o-filter-switch-list {
    margin: 10px 0;

    li {
        margin: 14px 0 8px 0;

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    // テキスト
    .a-toggle-btn-with-txt__txt {
        padding-right: 92px;//ボタン分のpadding
        font-size: 11px;
        text-align: right;
        color: $base-color;
        font-weight: bold;
    }
}
