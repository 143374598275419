@charset "utf-8";
// タテスクチケットアイコン

// ==============================================
// 読み込み
// ==============================================
@import "../../atoms/colors";
@import "../../templates/layout/ttsk_mixin";

// ==============================================
// スタイル
// ==============================================
// チケット×数字の組み合わせ
.m-ttsk-ticket-item {
    display: flex;
    align-items: center;
    color: $ttsk-text-color;
    word-break: keep-all;

    .image-ticket {
        margin-right: 1px;
        @include mq-up('md'){
            width: 30px;
            margin-right: 3px;
        }
    }
}

.m-ttsk-ticket-item {
    font-size: 18px;
    font-weight: bold;
}