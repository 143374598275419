@charset "UTF-8";

@import "../../atoms/colors";
@import "../../atoms/arrow-mixin";

// SPヘッダ左メニュー ランク表示

.o-header-rank-box {
    background-color: #f6f7f8; // TODO: 元の色。background-colorに合わせるか検討
}

.o-header-rank-box-rank-status {
    position: relative;
    overflow: hidden;
    display: block;
    padding: 8px;
}

/* MENU rank area */
.o-header-rank-box-rank-status {
    &:before {
        content: '';
        display: inline-block;
        float: left;
        width: 50px;
        height: 49px;
        background-repeat: no-repeat;
        background-size: 60px auto;
        background-image: url(/louis/sp/img/reading-note/common/sprite-rank-sp.png);
        color-scheme: only light;
    }

    &.queen:before {
        background-position: 50% -318px;
    }

    &.joker {
        pointer-events: none;

        &:before {
            background-position: 50% -475px;

            @media (prefers-color-scheme: dark) {
                background-color: #fff;
            }
        }

        &:after {
            display: none;
        }
    }

    &.ace:before {
        background-position: 50% -429px;

        @media (prefers-color-scheme: dark) {
            background-color: #fff;
        }
    }

    &.king:before {
        background-position: 50% -374px;
    }

    &.queen:before {
        background-position: 50% -318px;
    }

    &.jack:before {
        background-position: 50% -267px;
    }

    &.regular:before {
        background-position: 50% -220px;
    }

    &.light:before {
        background-position: 50% -173px;
    }

    &.noRank:before {
        background: none;
        width: 0;
        height: 0;
    }
}

// 開閉矢印
.o-header-rank-box-rank-status:after {
    @include down-arrow($base-color);
    // サイズが元より何故か小さくなる
    width: 12px;
    height: 12px;
    margin-top: -4px;
}

// 回転させる
.o-header-rank-box-rank-status.o-header-rank-box-rank-status--on:after {
    transform: translateY(-50%) rotate(135deg);
    margin-top: 2px;
}

// iOSは非表示に
.o-header-ios .o-header-rank-box-rank-status {
    pointer-events: none;

    &:after {
        display: none;
    }
}

.o-header-rank-box-status-txt {
    float: left;
    padding-left: 10px;
}

.o-header-rank-box-status-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 35px;
    display: block;
    text-align: left;
}

.o-header-rank-box-status-coin {
    line-height: 5px;
    display: block;
    text-align: left;

    span {
        font-size: 14px;
        color: #3385f5;
        font-weight: 700;
    }
}


.o-header-rank-box-condition {
    margin: 0 10px;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: .2s;

    &.o-header-rank-box-condition--on {
        max-height: 300px;
        transition: .25s;
        opacity: 1;
    }

    .o-header-rank-box-rank-status:after {
        display: none;
    }
}

.o-header-rank-box-condition:before {
    display: block;
    content: '';
    border-top: 1px solid $border-color;
    border-right: none;
    border-bottom: 1px solid #fff;
    border-left: none;
}

.o-header-rank-box-condition-limit {
    margin: 10px;
    font-size: 13px;
}

.o-header-rank-box-emphasis {
    font-weight: 700;
    font-size: 14px;
}

.o-header-rank-box-purchase {
    color: #e40007;
    font-weight: 700;
    font-size: 14px;
}

.o-header-rank-box-purchase-suffix {
    font-weight: 100;
    font-size: 10px;
}

.o-header-rank-box-condition-coin {
    line-height: 5px;
    text-align: left;
    font-size: 10px;
    display: inline-block;

    span {
        font-size: 14px;
        color: #3385f5;
        font-weight: 700;
    }
}

.o-header-rank-box-link {
    display: inline;
    padding: 0 3px;

    .ico-help {
        color: $base-color;
        font-weight: 400;
        font-size: 11px;
        vertical-align: super;
    }
}

.o-header-rank-box-condition-chart-box {
    display: block;
    margin: -10px 0 10px;
    text-align: center;
}

.o-header-rank-box-condition-chart {
    padding: 5px 0;

    span {
        display: inline-block;
    }
}

.o-header-rank-box-chart-box-rank,
.o-header-rank-box-condition-status-text-rank {
    &:before {
        content: '';
        display: inline-block;
        width: 30px;
        height: 27px;
        background-image: url(/louis/sp/img/reading-note/common/sprite-rank-sp.png);
        background-repeat: no-repeat;
        background-size: 60px auto;
        color-scheme: only light;
    }

    &.joker:before {
        background-position: 50% -684px;
        vertical-align: -7px;

        @media (prefers-color-scheme: dark) {
            background-color: #fff;
        }
    }

    &.ace:before {
        background-position: 50% -658px;
        vertical-align: -7px;

        @media (prefers-color-scheme: dark) {
            background-color: #fff;
        }
    }

    &.king:before {
        background-position: 50% -631px;
        vertical-align: -7px;
    }

    &.queen:before {
        background-position: 50% -601px;
        vertical-align: -7px;
    }

    &.jack:before {
        background-position: 50% -574px;
        vertical-align: -7px;
    }

    &.regular:before {
        background-position: 50% -547px;
        vertical-align: -7px;
    }

    &.light:before {
        background-position: 50% -521px;
        vertical-align: -7px;
    }
}


.o-header-rank-box-chart-box-status {
    position: relative;
    width: 75%;
    width: calc(100% - 70px);
    height: 9px;
    margin-top: 10px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #ccc;
    display: inline-block;
    overflow: hidden;

    span {
        float: none;
        position: absolute;
        left: 0;
        height: 7px;
        background: #e9137a;
        display: inline-block;
    }
}

