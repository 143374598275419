@charset "UTF-8";

@import "../../atoms/colors";


// SPレイアウトフッター
.o-footer-member {
    height: 28px;
    padding: 4px 0;
    background: $base-color;
    // ↓layout.scssのfooterスタイルを消す
    border: none;
    margin: 0;
}

.o-footer-member-copyright {
    background: $base-color;
    color: #fff;
    text-align: center;
    font-size: 12px;
}
