@import "../../templates/layout/font-variable";
@import "../../atoms/colors";
@import "../../atoms/z-index";
@import "../../atoms/heading";
@import "../../atoms/buttons/commons";
@import "../../atoms/tags";

@keyframes menuShowRight {
    from {
        right: -100%;
    }
    to {
        right: 0;
    }
}

@keyframes menuHideRight {
    from {
        right: 0;
    }
    to {
        right: -100%;
    }
}

.o-header-search-menu-content {
    right: -100%;
    padding: 58px 16px 10px;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    overflow-y: auto;
    z-index: $header-content-z-index;
    box-sizing: border-box;
    backface-visibility: hidden;
    @include mq-up('md') {
        max-width: 375px;
    }
    .o-header-search-menu-content-top {
        position: fixed;
        padding: 4px;
        top: 0;
        right: -100%;
        height: 48px;
        width: 100%;
        z-index: $header-content-top-z-index;
        text-align: center;
        background: #fff;
        border-bottom: 1px solid #eef0f2;
        @include mq-up('md') {
            max-width: 375px;
        }
        .o-header-search-menu-content-top__title {
            font-size: 16px;
            font-weight: bold;
            padding: 8px 0;
        }
        .o-header-search-menu-content-top-R {
            margin: 0 3px 0;
            position: absolute;
            top: 2px;
            right: 10px;
        }
    }

    &.o-header-search-menu-content--on {
        display: block;
        animation: menuShowRight .2s ease-in-out;
        animation-fill-mode: forwards;
        .o-header-search-menu-content-top {
            display: block;
            animation: menuShowRight .2s ease-in-out;
            animation-fill-mode: forwards;
        }
    }
    &.o-header-search-menu-content--off {
        animation: menuHideRight .2s 0s ease-in-out;
        animation-fill-mode: forwards;
        .o-header-search-menu-content-top {
            animation: menuHideRight .2s 0s ease-in-out;
            animation-fill-mode: forwards;
        }

    }
}

.o-header-search-menu-form {
    position: relative;
    border: 1px solid $border-color;
    border-radius: 4px;
    overflow: hidden;
}

.o-header-search-menu-select {
    position: absolute;
    width: 100px;
    height: 40px;
    opacity: 0;
    z-index: $header-menu-select-z-index;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    border-radius: 0;
    outline: 0;
}

.o-header-search-menu-drop-down-view {
    position: absolute;
    top: 0;
    left: 0;
    border-right: 1px solid #ccc;
    width: 100px;
    height: 40px;
    line-height: 30px;
    padding: 5px 15px 5px 5px;
    font-size: 11px;
    box-sizing: border-box;

    .ico-triangle_bottom {
        position: absolute;
        top: 50%;
        right: 3px;
        margin-top: -3px;
        font-size: 10px;
        display: block;
        color: $base-color;
        text-align: center;
    }
}

.o-header-search-menu-search-input:placeholder-shown {
    font-size: 12px;
}

.o-header-search-menu-search-input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    border-radius: 0;
    outline: 0;

    width: 100%;
    padding: 0 40px 0 105px;
    height: 38px;
    line-height: 38px;
    box-sizing: border-box;
    font-family: $base-font-family;
}

.o-header-search-menu-search-btn {
    border: none;
    cursor: pointer;
    outline: 0;
    appearance: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background: $base-color;

    .ico-search {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -12px;
        margin-left: -10px;
        color: #fff;
        font-size: 22px;
    }
}

.o-header-search-menu-search-del-btn-box {
    right: 40px;
    margin-top: -2px;

    display: none;
    position: absolute;
    top: 0;
    width: 44px;
    height: 44px;

    .o-header-search-menu-search-del-btn {
        position: absolute;
        top: 50%;
        right: 0;
        width: 35px;
        transform: translateY(-50%);
        text-align: center;

        .ico-close {
            display: inline;
            background: #babab1;
            color: #fff;
            font-size: 13px;
            border-radius: 50%;
            padding: 3px;
        }
    }
}

.o-header-menu-search-box {
    overflow: hidden;
    margin: 5px 0 15px -10px;

    li {
        width: 33%;
        width: calc(100% / 3);
        float: left;
        padding-top: 10px;
        padding-left: 10px;
    }
}

// キャンペーン・特集一覧/セットへのバナー
.o-header-serch-bnr-box {
    margin: 15px 4px;
    text-align: center;
    font-size: 0;

    li {
        display: inline-block;
        width: 46%;
        width: calc(calc(100% - 16px) / 2);
        max-width: 200px;
        margin: 0 16px 0 0;

        &:last-child {
            margin: 0;
        }
    }
}

// セーフサーチ
.o-header-search-safety {
    padding: 2px 10px;
    border-radius: 4px;
    border: 1px solid $border-color;

    .o-header-search-safety-head {
        position: relative;
        border-bottom: 1px dotted $border-color;

        .o-header-search-safety-ttl {
            height: 36px;
            font-size: 13px;
            font-weight: 700;
            line-height: 36px;
            color: $base-color;

            .ico {
                margin: 0 8px 0 0;
                font-size: 20px;
                vertical-align: middle;
            }

            .a-up-down-arrow-btn {
                right: -10px;
            }
        }

        .o-header-search-safety-ttl--off {
            border-bottom: none;
        }
    }

    .o-header-search-safety-head--off {
        border-bottom: none;
    }

    .o-filter-option {
        margin: 10px 8px;
    }

    .a-help-link {
        font-size: 12px;
        .ico {
            font-size: 11px;
        }
    }
}

.o-header-search-menu-list,
.o-header-search-genre-list {
    margin-top: 15px;
    padding: 6px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.o-header-search-menu-english-books {
    margin-top: 15px;
    border-radius: 4px;

    >a {
        font-weight: unset;
    }
}

.o-header-search-menu-list-ttl,
.o-header-search-genre-list-ttl {
    position: relative;
    height: 30px;
    padding: 0 10px;
    line-height: 30px;

    .a-heading-ttl {
        font-size: 13px;
        line-height: 30px;
        height: auto;
        border-bottom: 1px dotted $border-color;
        color: $base-color;

        &:before {
            left: 0;
        }
    }

    .a-heading-ttl--off {
        border-bottom: none;
    }

    .a-up-down-arrow-btn {
        right: 0;
    }
}

.o-header-search-menu-list-cont,
.o-header-search-genre-list-cont {
    overflow: hidden;
    padding: 0 5px;
    height: auto;
    //transition: all 300ms 0s ease-in-out; // NOTE: height: autoだと上手く動かない

    li {
        float: left;
        width: 50%;
        padding: 0 10px;
    }

    li a {
        display: block;
        line-height: 1.5;
        padding: 10px 10px 10px 8px;
        min-height: 40px;
        //max-height: 40px;
        border-top: 1px dashed $border-color;
        margin-top: -1px;
        text-align: left;
        font-size: 90%;
    }
}

.o-header-search-menu-list-cont--off,
.o-header-search-genre-list-cont--off {
    height: 0;
}
