@charset "UTF-8";

@import "../atoms/z-index";
@import "../atoms/colors";

// SPヘッダのメッセージポップアップ
.m-header-message-popup {
    position: absolute;
    top: 52px;
    right: 4px;
    width: 60%;
    max-width: 320px;
    padding: 8px 22px 4px 16px;
    border-radius: 4px;
    border: 1px solid $base-color;
    background: #fff;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
    font-size: 12px;
    visibility: hidden;
    opacity: 0;
    animation-name: popupBalloon;
    animation-duration: 3s;
    z-index: $header-content-z-index;

    // 見出し文と件数
    .m-header-message-popup-head, .m-header-message-popup-number {
        display: inline-block;
        text-align: left;
        text-decoration: underline;
        line-height: 1.3;
        font-weight: 700;
        color: $menu-badge-color;
    }

    .m-header-message-popup-unread {
        margin-top: 4px;
        text-align: center;

        // 件数
        .m-header-message-popup-number {
            padding: 0 6px;
            font-size: 14px;
        }
    }

    // 閉じるボタン
    .a-modal-close-btn-box {
        padding: 3px 8px 25px 5px;
        top: 0;
        right: -5px;
    }
}
