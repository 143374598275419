@charset "utf-8";
// 話読みタグ

// ==============================================
// 読み込み
// ==============================================
@import "../colors";
@import "../../templates/layout/mixin";
@import "../../templates/layout/ttsk_mixin";
// ==============================================
// スタイル
// ==============================================
.a-tag-ttsk {
	a {
		display: inline-block;
		font-size: 10px;
		line-height: 1.5;
		font-weight: bold;
		color: $ttsk-blue-color;
		background-color: #e4f6fd;
		padding: 4px 10px;
		&:hover {
			@include mq-up('md'){
				background-color: #9de0fb;
				opacity: 1;
			}
		}
		@include mq-up('md'){
			font-size: 12px;
			padding: 5px 10px;
		}
	}
}

// 角丸タグのベース
// ==============================================
@mixin round-tag-base {
	border-radius: 15px;
	color: #fff;
	padding: 5px 12px;
	font-size: 12px;
	font-weight: bold;
	line-height: 1;
	@include mq-up('md'){
		font-size: 13px;
		padding: 6px 17px;
	}
}

// 角丸・赤色タグ
.a-ttsk-tag--round-red {
	@include round-tag-base;
	background: $ttsk-red-color;
}

// #付きタグ
// ==============================================
// フレームあり
.a-ttsk-tag--sharp {
	display: block;
	font-size: 10px;
	font-weight: bold;
	box-sizing: border-box;
	border-radius: 22px;
	border: 1px solid #ccd7e0;
	color: $ttsk-dark-blue-gray-color;
	cursor: pointer;
	padding: 4px 10px 4px 19px;
	text-indent: -0.9em;
	word-break: break-all;
	span {
		color: $ttsk-blue-color;
		padding-right: 1px;
	}
	&:hover {
		@include mq-up('md'){
			background-color: #e8f1f9;
			opacity: 1;
		}
	}
}

// フレームなし&リンクなし
.a-ttsk-tag--sharp-no-frame {
	display: block;
	font-size: 10px;
	font-weight: bold;
	box-sizing: border-box;
	color: #666;
	padding-left: 1em;
	text-indent: -0.8em;
	word-break: break-all;
	span {
		color: #666;
		padding-right: 1px;
	}
}

// スクエアタグ・購入済/未購入で使用
// ==============================================
.a-ttsk-tag--square-gray,
%a-ttsk-tag--square-gray {
	display: inline-block;
	font-size: 10px;
	line-height: 1;
	box-sizing: border-box;
	border: 1px solid $ttsk-text-color;
	color: $ttsk-light-text-color;
	padding: 2px 4px;
}
.a-ttsk-tag--purchased,
.a-ttsk-tag--not-purchased {
	@extend %a-ttsk-tag--square-gray;
}

// スクエアタグ赤・セール訴求で使用
// ==============================================
.a-ttsk-tag--square-red,
%a-ttsk-tag--square-red {
	display: inline-block;
	font-size: 10px;
	line-height: 1;
	box-sizing: border-box;
	border: 1px solid $ttsk-red-color;
	color: $ttsk-red-color;
	padding: 2px 4px;
}
.a-ttsk-tag--sale {
	@extend %a-ttsk-tag--square-red;
}

// 読み放題タグ
// ==============================================
.a-ttsk-tag-subscription {
	display: flex;
	align-items: center;
	justify-content: center;
	color: $sbsc-tag-color;
	border: solid 1px $sbsc-tag-color;
	font-size: 10px;
	font-weight: bold;
	line-height: 1.5;
	background-color: #fff;

	.a-ttsk-icon--subscription {

		&::before {
			width: 14px;
			height: 11px;
			background: url('/anne/img/ttsk/icon-subscription-purple.svg') center center/contain no-repeat;
		}
	}
}

.a-ttsk-tag-subscription--small {
	display: block;
	line-height: 1;
}