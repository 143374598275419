// 話連載ヘッダ左メニュー コイン表示
// 煩雑になるのでチケットの部分から分離

@import "../../atoms/colors";
@import "../../templates/layout/font-variable";

.m-ttsk-header-left-menu-coin {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 14px;
    line-height: 1.5;

    // 元々ついているスタイルの上書き
    .m-ttsk-coin-item,
    .m-ttsk-point-item {
        font-size: 14px;

        .image-coin {
            width: 16px;
        }
    }

    .m-ttsk-header-left-menu-coin-expire {
        font-size: 10px;

        .m-ttsk-header-left-menu-coin-expire-price {
            color: $strong-red;
            font-weight: $font-bold;
        }
    }
}