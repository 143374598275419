@charset "utf-8";
// ログイン画面のベース
// （ログインモーダル・ヘッダー内メニュー・読了後で読み込み）

// ==============================================
// 読み込み
// ==============================================
@import "../../atoms/buttons/ttsk-btn";
@import "../../atoms/colors";
@import "../../atoms/z-index";
@import "../../atoms/ttsk/ttsk-title";
@import "../../atoms/ttsk/ttsk-text";

// ==============================================
// スタイル
// ==============================================
.m-ttsk-login__header,
%m-ttsk-login__header {
    border-bottom: 1px solid $ttsk-blue-color;
    padding-top: 22px;
    margin-bottom: 24px;
}
.m-ttsk-login__title,
%m-ttsk-login__title {
    padding-bottom: 4px;
    font-size: 15px;
    @include mq-up('md'){
        font-size: 22px;
    }
}

// 新規会員登録
.m-ttsk-login__registration,
%m-ttsk-login__registration {
    padding: 0 0 24px;
    border-bottom: 1px solid $ttsk-border-color;
    .a-ttsk-text--annotation {
        margin-top: 10px;
        padding: 0 16px;
        text-align: center;
        @include mq-up('md') {
            font-size: 10px;
        }
    }
}

.m-ttsk-login__thumb,
%m-ttsk-login__thumb {
    margin: 0 auto;
    text-align: center;
    img {
        width: 100%;
        max-width: 500px;
        vertical-align: middle;
    }
}

// ログイン
.m-ttsk-login__login,
%m-ttsk-login__login {
    margin-top: 15px;
}

.m-ttsk-login__body-text,
%m-ttsk-login__body-text {
    font-size: 12px;
    font-weight: 700;
    line-height: 1.28;
    text-align: center;
}

.m-ttsk-modal__body .a-ttsk-btn {
    margin-top: 10px;
}