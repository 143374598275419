// atomとしてのボタン定義をここに書く
// TODO: 一旦は一ファイルで書いているが、大きくなり過ぎたら分割すること。
@import "../../../../../bob/fonts/style";
@import "../../templates/layout/font-variable";
@import "../colors";
@import "../labels";
@import "../../atoms/z-index";
@import "../../templates/layout/ttsk_mixin";


// だいたいのアイコンは44px x 44px TODO: 要検証
$base-icon-size: 44px;
$mq-xs-icon-size: 38px;

.ico-txt {
    font-family: $base-font-family !important;
}

@mixin header-icon-common {
    padding-top: 4px;
    height: $base-icon-size;
    text-align: center;
    color: $base-color;
    display: block;
    @include baseTransition;
    &:hover {
        @include mq-up('md') {
            opacity: 1;
            color: #4D6880;
        }
    }
}

// 既存アイコン(bw)
@mixin header-bw-icon-common {
    display: block;
    text-align: center;
}

// google material icons
@mixin header-material-icon-common($type: icon) {
    line-height: 1;
    @if $type == icon {
        &::before {
            font-family: 'Material Icons Outlined';
            font-size: 24px;
        }
    } @else if $type == symbol {
        &::before {
            font-family: 'Material Symbols Outlined';
            font-size: 24px;
            font-variation-settings:
                'FILL' 0,
                'wght' 400,
                'GRAD' 0,
                'opsz' 48
        }
    }
}

// google material iconsの下テキスト
@mixin header-material-icon-text {
    display: block;
    font-size: 10px;
    font-weight: normal;
    letter-spacing: -0.1em;
    line-height: 1.5;
    margin-top: -4px;
}


// 既存アイコン(bw)の下テキスト
@mixin header-bw-icon-text {
    display: block;
    font-size: 10px;
    font-weight: normal;
    letter-spacing: -0.1em;
    line-height: 1.5;
}

// ヘッダロゴ
.a-header-logo {
    display: block;
    width: 75px;
    height: 42px;
    overflow: hidden;
    background: url(/anne/img/sp/logo_bookwalker_group.svg) no-repeat center/contain;
    font-size: 0;
    text-indent: 100%;
    white-space: nowrap;
    @include baseTransition;

    @include mq-up('md') {
        width: 165px;
        height: 36px;
        background: url(/anne/img/pc/logo_bookwalker_group.svg) no-repeat center/contain;
    }
}

// メニューボタン
.a-header-menu-btn,
%a-header-menu-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: $base-icon-size;
    color: $base-color;
    padding-top: 2px;
    @include baseTransition;
    @include mq-up('lg') {
        margin-left: -10px;
    }
    &:hover {
        @include mq-up('md') {
            opacity: 1;
            color: #4D6880;
        }
    }
    .a-icon--menu {
        @include header-material-icon-common;
        &::before {
            content: '\e5d2';
        }
    }
    &.a-header-menu-btn--off {
        margin-top: -50px;
        opacity: 0;
        transition: .2s;
    }
}

// ヘッダ会員登録ボタン
.a-header-sign-in-btn {
    @include header-icon-common;
    position: relative;
    @include mq-down('xs') {
        padding-right: 3px;
        padding-left: 3px;
    }

    .ico-member {
        @include header-bw-icon-common;
        font-size: 19px;
        padding-top: 2px;
        padding-bottom: 5px;
    }

    .ico-txt {
        @include header-bw-icon-text;
    }
}

// ヘッダログインボタン
.a-header-login-btn {
    @include header-icon-common;
    position: relative;

    @include mq-down('xs') {
        margin-left: 5px;
        padding-right: 3px;
        padding-left: 3px;
    }

    .ico-loginout {
        @include header-bw-icon-common;
        font-size: 19px;
        padding-top: 2px;
        padding-bottom: 5px;
    }

    .ico-txt {
        @include header-bw-icon-text;
    }
}

// 話連載デザインのヘッダログインボタン
.a-header-login-btn_warensai {
    @include header-icon-common;
    @include mq-down('xs') {
        margin-left: 5px;
        padding-right: 3px;
        padding-left: 3px;
    }

    .a-icon--login_warensai {
        @include header-material-icon-common;
        &::before {
            content: '\ea77';
        }
    }
    .ico-txt {
        @include header-material-icon-text;
    }
}

// 話連載デザインのヘッダ会員登録ボタン
.a-header-sign-in-btn_warensai {
    @include header-icon-common;
    @include mq-down('xs') {
        padding-right: 3px;
        padding-left: 3px;
    }

    .a-icon--sign-in_warensai {
        @include header-material-icon-common;
        &::before {
            content: '\e7fe';
        }
    }
    .ico-txt {
        @include header-material-icon-text;
    }
}

// メニュー閉じるボタン
.a-header-menu-close-btn {
    @extend %a-header-menu-btn;

    .a-icon--menu-close {
        @include header-material-icon-common;
        &::before {
            content: '\e5cd';
        }
    }
}

// ヘッダはじめての方へボタン
.a-header-beginner-btn {
    position: relative;
    @include header-icon-common;

    .ico-beginner {
        @include header-bw-icon-common;
        font-size: 20px;
        padding-top: 2px;
        padding-bottom: 3px;
    }

    .ico-txt {
        @include header-bw-icon-text;
    }
}

// ヘッダ本を探すボタン
.a-header-search-btn {
    @include header-icon-common;

    .a-icon--search {
        @include header-material-icon-common;
        &::before {
            content: '\e8b6';
        }
    }

    .ico-txt {
        @include header-material-icon-text;
    }
}

@mixin badge {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    padding: 0 4px;
    background: $menu-badge-color;
    border-radius: 8px;
    font-size: 10px;
    color: #fff;
    font-weight: 700;
    text-align: center;
}

@mixin badge-circle($badge-color: $ttsk-red-color) {
    position: absolute;
    top: 3px;
    right: 9px;
    display: block;
    width: 8px;
    height: 8px;
    background: $badge-color;
    border-radius: 50%;
}

// チェックボタン
.a-header-check-btn {
    @include header-icon-common;
    position: relative;

    .ico-check {
        @include header-bw-icon-common;
        font-size: 19px;
        padding-top: 2px;
        padding-bottom: 4px;
    }

    .ico-txt {
        @include header-bw-icon-text;
    }

    .a-header-check-btn-badge {
        @include badge;
    }
}

// お知らせボタン
.a-header-message-btn {
    @include header-icon-common;
    position: relative;

    .ico-mail {
        @include header-bw-icon-common;
        font-size: 19px;
        padding-top: 4px;
        padding-bottom: 2px;
    }

    .a-icon--mail {
        @include header-material-icon-common(symbol);
        &::before {
            content: '\e158';
        }
    }

    .ico-mail + .ico-txt {
        @include header-bw-icon-text;
    }

    .a-icon--mail + .ico-txt {
        @include header-material-icon-text;
    }

    .a-header-message-btn-badge {
        @include badge;
    }

    .a-header-message-btn-warensai-badge {
        @include badge-circle;
    }
}

// カートボタン
.a-header-cart-btn {
    @include header-icon-common;
    position: relative;

    .ico-cart {
        @include header-bw-icon-common;
        font-size: 19px;
        padding-top: 2px;
        padding-bottom: 4px;
    }

    .ico-txt {
        @include header-bw-icon-text;
    }

    .a-header-cart-btn-badge {
        @include badge;
    }
}


// アプリへ戻るボタン
.a-header-back-to-app-btn {
    @include header-icon-common;
    position: relative;

    .ico-return-app{
        @include header-bw-icon-common;
        font-size: 19px;
        padding-top: 2px;
        padding-bottom: 4px;
    }
    .ico-txt {
        @include header-bw-icon-text;
    }
}

// 読んだ作品ボタン
.a-header-read-btn {
    @include header-icon-common;
    position: relative;

    .a-icon--read {
        @include header-material-icon-common(symbol);
        &::before {
            content: '\e0e0';
        }
    }

    .ico-txt {
        @include header-material-icon-text;
    }
    .ico-txt {
        display: block;
        padding-right: 0.1em;
    }
}

// お気に入りボタン
.a-header-favorite-btn {
    @include header-icon-common;
    position: relative;

    .a-icon--favorite {
        @include header-material-icon-common;
        &::before {
            content: '\e83a';
        }
    }

    .ico-txt {
        @include header-material-icon-text;
    }
    .ico-txt {
        display: block;
    }
    .a-header-favorite-btn-badge {
        @include badge-circle;
    }
}

// ハンバーガーメニュー内閲覧履歴ボタン
.a-header-history-long-btn {
    height: 44px;
    line-height: 42px;
    -webkit-tap-highlight-color: rgba(123, 200, 206, .3);
    display: block;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #ccc;

    .ico {
        font-size: 22px;
        display: inline-block;
        vertical-align: middle;
    }

    .ico-txt {
        padding-left: 5px;
        font-size: 100%;
        font-weight: 700;
        display: inline-block;
        vertical-align: middle;
    }
}

// ヘッダログイン長いボタン
.a-header-long-login-btn {
    height: 44px;
    line-height: 42px;
    -webkit-tap-highlight-color: rgba(123, 200, 206, .3);
    display: block;
    text-align: center;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #ccc;

    .ico {
        font-size: 22px;
        display: inline-block;
        vertical-align: middle;
    }

    .ico-txt {
        padding-left: 5px;
        font-size: 100%;
        font-weight: 700;
        display: inline-block;
        vertical-align: middle;
    }
}

// ヘッダログアウト長いボタン
.a-header-my-page-btn {
    height: 44px;
    display: block;
    text-align: center;
    background: #fff;

    .ico {
        font-size: 22px;
        display: inline-block;
        vertical-align: middle;
    }

    .ico-txt {
        padding-left: 5px;
        font-size: 100%;
        font-weight: 700;
        display: inline-block;
        vertical-align: middle;
    }
}

// ヘッダ会員登録長いボタン
.a-header-long-sign-in-btn {
    height: 46px;
    line-height: 1;
    padding: 12px 10px;
    font-size: 16px;
    background: $sign-in-btn-color;
    border-radius: 23px;
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, .4) inset;
    display: block;
    text-align: center;

    .ico-member {
        display: inline-block;
        vertical-align: middle;
        color: #fff;
        font-size: 22px;
    }

    .ico-txt {
        display: inline-block;
        vertical-align: middle;
        color: #fff;
        padding-left: 5px;
        font-size: 100%;
        font-weight: 700;
    }
}

@mixin header-search-menu-content-buttons {
    @include header-icon-common;
    border-radius: 4px;
    border: 1px solid $border-color;
    height: auto;
    position: relative;

    .ico {
        padding: 2px;
        color: $base-color;
        font-size: 26px;
        display: block;
        text-align: center;
    }

    .ico-txt {
        font-size: 10px;
        font-weight: 700;
        line-height: 1.2;
        display: block;
    }
}

.a-header-new-items {
    @include header-search-menu-content-buttons;

    .a-label-new {
        background-size: 32px 31px;
        width: 32px;
        height: 31px;
        right: 0;
        top: 0;
    }
}

.a-header-schedule-items {
    @include header-search-menu-content-buttons;

    .a-label-reserve {
        background-size: 32px 31px;
        width: 32px;
        height: 31px;
        right: 0;
        top: 0;
    }
}

.a-header-ranking-link {
    @include header-search-menu-content-buttons;

    .ico {
        color: $first-rank-color;
    }
}

.a-header-limited-link {
    @include header-search-menu-content-buttons;
}

.a-header-coin-up-link {
    @include header-search-menu-content-buttons;

    .a-label-coin-up-1 {
        background-size: 12px 33px;
        width: 12px;
        height: 33px;

        left: inherit;
        bottom: inherit;
        top: -1px;
        right: 5px;
    }
}

.a-header-free-link {
    @include header-search-menu-content-buttons;
}

// キャンペーン・特集一覧のバナー
.a-header-special-link {
    display: block;

    img {
        width: 100%;
        height: auto;
    }
}

// セット商品へのバナー
.a-header-set-link {
    @extend .a-header-special-link;
}


// NOTE: 左メニュー内で同一スタイルしてたものなので、atomでCSS書くか悩ましい
%header-left-tile-items {
    @include header-icon-common;
    height: auto;

    .ico {
        display: block;
        font-size: 32px;
    }

    .ico + .ico-txt {
        padding-top: 5px;
        padding-left: 0;
        font-size: 85%;
        font-weight: 700;
    }
}

.a-header-bought-books-btn {
    @extend %header-left-tile-items;
}

.a-header-reserved-books-btn {
    @extend %header-left-tile-items;
}

.a-header-sequels-btn {
    @extend %header-left-tile-items;
}

.a-header-follow-btn {
    @extend %header-left-tile-items;
}

.a-header-history-btn {
    @extend %header-left-tile-items;
}

.a-header-left-menu-check-btn {
    @extend %header-left-tile-items;
}

.a-header-left-menu-cart-btn {
    @extend %header-left-tile-items;
}

.a-header-left-menu-message-btn {
    @extend %header-left-tile-items;
}

.a-header-reading-note-btn {
    @extend %header-left-tile-items;
}

.a-header-my-page-btn {
    @extend %header-left-tile-items;
}

%header-left-tile-bottom-items {
    @include header-icon-common;

    .ico {
        display: block;
        font-size: 19px;
    }

    .ico + .ico-txt {
        display: block;
        padding-top: 5px;
        padding-left: 0;
        font-size: 85%;
        font-weight: 700;
    }
}

.a-header-my-page-half-btn {
    @extend %header-left-tile-bottom-items;
}

.a-header-logout-btn {
    @extend %header-left-tile-bottom-items;
}


// member.から巻き取ったヘッダー用のスタイル

.a-header-member-logo {
    display: block;
    width: 240px;
    height: 40px;
    background: transparent url(/bob/pc/img/logo_bookwalker.svg) no-repeat 50% 50%;
    background-size: contain;
    font-size: 0;

    @media (max-width: 1024px) {
        width: 165px;
    }

    @media (max-width: 350px) {
        width: 150px;
    }

    span {
        display: block;
        width: 240px;
        height: 40px;
        background: transparent url(/bob/pc/img/logo_bookwalker.svg) no-repeat 50% 50%;
        background-size: contain;
        font-size: 0;

        @media (max-width: 1024px) {
            width: 165px;
        }

        @media (max-width: 350px) {
            width: 150px;
        }
    }
}


.a-header-member-logout-btn,
.a-header-member-login-btn {
    display: block;
    padding: 4px 4px 2px 4px;
    color: #1d4a6d;
    font-weight: bold;
    border-radius: 4px;
    border: 1px solid #ccc;
    cursor: pointer;
    &:hover {
        @include mq-up('md') {
            @include hover;
        }
    }

    .ico-loginout {
        display: block;
        width: 60px;
        font-size: 19px;
        text-align: center;
    }
    .ico-txt {
        display: block;
        padding-top: 3px;
        font-size: 10px;
        font-weight: bold;
        text-align: center;
    }
}

//言語切り替え部分の切り替え
.a-header-member-language-btn .ico-txt.change-ja, .change-ja,
.a-header-member-language-btn .ico-txt.change-en, .change-en {
    display: none;
}

//ログイン部分の言語切り替え
.o-header-member__btns-ja .a-header-member-language-btn .ico-txt.change-ja, .o-header-member__btns-ja .change-ja,
.o-header-member__btns-en .a-header-member-language-btn .ico-txt.change-en, .o-header-member__btns-en .change-en {
    display: block;
}

.a-header-member-language-btn {
    position: relative;
    display: block;
    width: 65px;
    padding: 4px 4px 2px 4px;
    color: #666;
    font-weight: bold;
    border-radius: 4px;
    border: 1px solid #ccc;
    cursor: pointer;
    &:hover {
        @include mq-up('md') {
            @include hover;
        }
    }
    .ico-browser {
        display: block;
        font-size: 19px;
        text-align: center;
    }

    .ico-txt {
        display: block;
        padding-top: 3px;
        font-size: 10px;
        font-weight: bold;
        text-align: center;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 48px;
        height: 38px;
        background: none;
        border: none;
        color: transparent;
        font-size: 16px;
        cursor: pointer;
    }

    option {
        color: #2f2f2f;
        font-weight: bold;
    }

    .fn-header-member-language-switch:focus {
        outline: none;
    }
}

// 探索ボタン
.a-header-explore-btn {
    @include header-icon-common;
    position: relative;
    padding-top: 8px;
    line-height: 1;

    .a-header-explore-btn-icon {
        display: block;
        width: 20px;
        height: 21px;
        background: url(/anne/img/common/icon/icon_explore.svg) no-repeat center/contain;
        margin: auto;
    }

    .ico-txt {
        @include header-bw-icon-text;
    }

    .a-header-explore-btn-badge {
        @include badge-circle($menu-badge-color);
        top: 6px;
    }
}
