// 各種BW公式アカウントへのリンク用ボタン（丸い方のSNSボタン周り）
@import "../atoms/colors";

.m-official-btn-list {
    margin: 8px auto;
    padding: 12px 10px 8px;
    border-top: 1px dotted $border-color;
    text-align: center;
    font-size: 0;

    li {
        display: inline-block;
        margin: 0 10px;
        vertical-align: top;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    .m-official-btn-list-ttl {
        display: block;
        margin-bottom: 10px;
        font-size: 11px;
        font-weight: 700;
        text-align: center;
        a {
            display:inline-block;
            position:relative;
            padding:0 0.5em;
            &:before,
            &:after {
                display:inline-block;
                position:absolute;
                top:calc(50% - 0.5em);
                width:1px;
                height:1em;
                background-color:$base-link-color;
                content:"";
            }
            &:before {
                left:0;
                -webkit-transform: rotate(-45deg);
                transform:rotate(-45deg);
            }
            &:after {
                right:0;
                -webkit-transform:rotate(45deg);
                transform:rotate(45deg);
            }
        }
    }
}
