@import "../../../templates/layout/font-variable";
@import "../../../atoms/colors";
@import "../../../atoms/z-index";
@import "../../../atoms/heading";
@import "../../../atoms/buttons/commons";
@import "../../../atoms/buttons/ttsk-up-down-arrow-btn";
@import "../../../atoms/buttons/ttsk-close-btn";
@import "../../../atoms/tags";
@import "../../../atoms/ttsk/ttsk-icon";
@import "../../../atoms/ttsk/ttsk-tag";
@import "../../../atoms/ttsk/ttsk-label";
@import "../../../molecules/ttsk/ttsk-thumb";
@import "../../../molecules/ttsk/ttsk-toggle-btn-with-txt";
@import "../../ttsk/ttsk-section";
@import "../../ttsk/ttsk-tile";

@keyframes menuShowRight {
    from {
        right: -100%;
    }
    to {
        right: 0;
    }
}

@keyframes menuHideRight {
    from {
        right: 0;
    }
    to {
        right: -100%;
    }
}

.o-ttsk-header-search-menu-content {
    right: -100%;
    padding: 48px 0 10px;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    overflow-y: auto;
    z-index: $header-content-z-index;
    box-sizing: border-box;
    backface-visibility: hidden;
    @include mq-up('md') {
        max-width: 375px;
    }

    .o-ttsk-header-search-menu-content-top {
        position: fixed;
        padding: 4px;
        top: 0;
        right: -100%;
        height: 48px;
        width: 100%;
        z-index: $header-content-top-z-index;
        text-align: center;
        background: #fff;
        border-bottom: 1px solid #eef0f2;
        @include mq-up('md') {
            max-width: 375px;
        }
        .o-header-search-menu-content-top__title {
            font-size: 16px;
            font-weight: bold;
            padding: 8px 0;
        }
        .o-header-search-menu-content-top-R {
            margin: 0 3px 0;
            position: absolute;
            top: 2px;
            right: 10px;
        }
    }

    &.o-ttsk-header-search-menu-content--on {
        display: block;
        animation: menuShowRight .2s ease-in-out;
        animation-fill-mode: forwards;
        overflow-x: hidden;
        .o-ttsk-header-search-menu-content-top {
            display: block;
            animation: menuShowRight .2s ease-in-out;
            animation-fill-mode: forwards;
        }
    }
    &.o-ttsk-header-search-menu-content--off {
        animation: menuHideRight .2s 0s ease-in-out;
        animation-fill-mode: forwards;
        .o-ttsk-header-search-menu-content-top {
            animation: menuHideRight .2s 0s ease-in-out;
            animation-fill-mode: forwards;
        }
    }
}

.o-ttsk-header-search-menu-form {
    margin: 24px 0 30px;
    padding: 0 13px;
    position: relative;
    overflow: hidden;
    text-align: center;

    .a-ttsk-icon--search {
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translateY(-50%);
        &::before {
            font-size: 22px;
            color: #666;
        }
    }
}

.o-ttsk-header-search-menu-search-input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: solid 1px $border-color;
    border-radius: 24px;
    outline: none;
    box-shadow: none;

    margin: 3px;
    width: calc(100% - 8px);
    height: 48px;
    font-family: $base-font-family;
    font-size: 12px;
    padding: 0 10px 0 40px;
    vertical-align: middle;
    box-sizing: border-box;
    &:focus {
        box-shadow: 0 0 0 3px #CEF0FD;
        //padding-right: 44px;
    }
}

.o-ttsk-header-search-menu-search-input:placeholder-shown {
    color: #666;
    font-size: 10px;
    padding-right: 10px;
    @media screen and (min-width: 370px) {
        font-size: 12px;
    }
}

.o-ttsk-header-search-menu-search-input:not(placeholder-shown) {
    padding-right: 44px;
}


.o-ttsk-header-search-menu-search-del-btn-box {
    right: 16px;
    margin-top: 0;
    display: none;
    position: absolute;
    top: 4px;
    width: 44px;
    height: 48px;

    .o-ttsk-header-search-menu-search-del-btn {
        position: absolute;
        top: 50%;
        right: 0;
        width: 44px;
        transform: translateY(-50%);
        text-align: center;
        .a-ttsk-icon--clear {
            &::before {
                color: #999;
                font-size: 20px;
            }
        }
    }
}

.o-ttsk-search-menu-keyword-area {
    border-bottom: 1px solid $ttsk-blue-color;
    .o-ttsk-search-menu-keyword {
        display: flex;
        flex-wrap: wrap;
        padding: 14px 12px;

        li {
            margin: 10px 4px;
            a {
                font-size: 12px;
                padding: 4px 9px 4px 20px;
            }
        }
    }
}

.o-ttsk-search-menu-safesearch-area {
    margin-top: 30px;
    padding-top: 7px;

    .o-ttsk-section__title--simple-border {
        // 開閉できるのでカーソルをポインターにする
        cursor: pointer;
    }

    .o-ttsk-search-menu-safesearch-area__notice {
        font-size: 10px;
        color: $ttsk-light-text-color;
        margin: 0 16px;
        padding: 16px 0 0 1em;
        text-indent: -1em;
    }

    .o-ttsk-search-menu-safesearch-area__collapse {
        display: none;
        padding-bottom: 0;
        border-bottom: none;
        &.opened {
            display: block;
            padding-bottom: 20px;
            border-bottom: 1px solid $ttsk-blue-color;
        }
    }
}

// タテスク用のスタイル上書き
.o-ttsk-search-menu-keyword-area .o-ttsk-section__title--simple-border,
.o-ttsk-search-menu-safesearch-area .o-ttsk-section__title--simple-border {
    border-color: $ttsk-blue-color;
    margin-bottom: 0;
    padding-bottom: 5px;
    .a-ttsk-title--icon {
        font-size: 15px;
        @include mq-up('md') {
            font-size: 15px;
        }
        @include mq-up('lg') {
            margin: 0 16px;
        }
    }
}


// タテスク用のスタイル上書き
// sectionのPC用のメディアクエリを全部スマホ用サイズで上書き
.o-ttsk-header-search-menu-content {
    .o-ttsk-section__inner {
        border-width: 4px;
    }

    .a-ttsk-tag--sharp span {
        padding-right: 2px;
    }

    .o-ttsk-section--yellow {
        margin-top: 30px;
        padding-top: 7px;
        @include mq-up('md') {
            margin-bottom: 70px;
        }
        &::before,
        &::after {
            //width: 100%;
            @include mq-up('md') {
                height: 45px;
            }
        }
        &:before {
            @media screen and (min-width: 414.98px) {
                transform: rotate(2deg);
            }
            @include mq-up('md') {
                transform: rotate(3deg);
            }
        }
        &:after {
            @media screen and (min-width: 414.98px) {
                transform: rotate(4deg);
            }
            @include mq-up('md') {
                transform: rotate(6deg);
            }
        }

        .a-ttsk-read-more-btn {
            width: calc(100% - 32px);
        }
    }

    .o-ttsk-section__title {
        border-color: $ttsk-yellow-color;
        @include mq-up('md') {
            border-bottom: 4px solid $ttsk-yellow-color;
        }
        .a-ttsk-title--icon {
            @include mq-up('md') {
                font-size: 16px;
            }
            @include mq-up('lg') {
                margin: 0 16px;
            }
            &::after {
                @include mq-up('md') {
                    width: 32px;
                    height: 4px;
                    bottom: -9px;
                    background-image: linear-gradient(90deg, $ttsk-blue-color 0, $ttsk-blue-color 8px, $ttsk-yellow-color 8px, $ttsk-yellow-color 16px, $ttsk-green-color 16px, $ttsk-green-color 24px, $ttsk-red-color 24px, $ttsk-red-color 32px);
                }
            }
        }
    }

    .o-ttsk-tile__list {
        padding: 0 16px;
        @media screen and (min-width: 521px) and (max-width: 767.98px) {
            grid-template-columns: repeat(6, 1fr);
            gap: 24px 3.34%;
        }
        @include mq-up('md') {
            grid-template-columns: repeat(3, 1fr);
            gap: 24px 5%;
        }
        a {
            @include baseTransition;
            &:hover {
                @include mq-up('md') {
                    @include hover;
                }
            }
        }
    }

    .a-ttsk-read-more-btn {
        max-width: 600px;
        margin-top: 20px;
        font-size: 14px;
        padding: 13px 0;
    }
}

