// 話連載コイン導入時のお知らせ
// 期間限定なので2022/10過ぎたら消す

// ==============================================
// 読み込み
// ==============================================
@import "../../templates/layout/ttsk_mixin";
@import "../../templates/layout/font-variable";
@import "../../atoms/colors";

// ==============================================
// スタイル
// ==============================================
.m-ttsk-header-info {
    position: relative;
    background-color: $ttsk-light-blue-gray-03-color;
    border-bottom: 1px solid $ttsk-border-color;
    height: 39px;

    .m-ttsk-header-info-link {
        position: relative;
        display: block;
        color: $ttsk-blue-gray-color;
        font-size: 12px;
        font-weight: $font-bold;
        line-height: 1.5;
        padding: 10px 0;
        z-index: 10;

        &:hover {
            @include mq-up('md') {
                @include textHover;
            }
        }
    }

    .m-ttsk-header-info-inner {
        margin: 0 auto;
        padding-left: 10px;

        @include mq-up('xs') {
            padding-left: 15px;
        }

        @include mq-up('md') {
            max-width: 1080px;
        }

        @include mq-up('lg') {
            max-width: 1102px;
            padding-left: 14px;
        }
    }

    .a-header-menu-close-btn-box {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    .a-header-menu-close-btn {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $ttsk-blue-gray-color;
        height: 38px;
        margin-left: auto;
        //atomに元々ついているスタイルの打消し
        padding-top: 0;
        z-index: 20;

        .a-icon--menu-close::before {
            font-size: 18px;
        }

        &:hover {
            @include mq-up('md') {
                color: $ttsk-light-blue-gray-color;
            }
        }
    }

    &.m-ttsk-header-info--close {
        opacity: 0;
        visibility: hidden;
        @include baseTransition;
    }
}
