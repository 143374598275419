@charset "utf-8";
// タテスクトグルボタン

// ============================
// 読み込み
// ============================
@import "../colors";
@import "../../templates/layout/ttsk_mixin";

// ==============================================
// スタイル
// ==============================================
// トグルボタン

.a-ttsk-toggle-btn {
    display: inline-block;
    height: 28px;
    vertical-align: middle;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    input {
        display: none;
    }

    // トグルボタン全体
    .a-ttsk-toggle-btn__label {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 28px;
        border-radius: 20px;
        background: $weak-gray;

        // OFFの時・テキスト部分
        &::before {
            content: attr(data-off-label);
            position: absolute;
            width: 80px;
            padding:5px 0 5px 25px;
            text-align: center;
            color: #fff;
        }

        // OFFの時・ボタン部分
        &::after {
            content: '';
            position: absolute;
            top: 3px;
            left: 3px;
            width: 22px;
            height: 22px;
            background: #fff;
            border-radius: 50%;
            //transitionするプロパティを増やすとsafe-search.jsが上手く発火しなくなるので注意#44755
            transition: left 0.15s;
        }
    }

    // ONの時・背景色
    .a-ttsk-toggle-btn__input:checked + .a-ttsk-toggle-btn__label {
        background: $ttsk-blue-color;

        // ONの時・テキスト部分
        &::before {
            content: attr(data-on-label);
            padding:5px 25px 5px 0;
        }

        // ONの時・ボタン部分
        &::after {
            left: 55px;
            background: #fff;
        }
    }
}