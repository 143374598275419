/* ====================================================================================
	footer-ABJ （SP：ABJマーク） （asset/louis/sp/scss/parts/_abj.scssからパクってきた）
======================================================================================= */
.o-footer-ABJ {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    margin: 12px auto;
    background: #fff;

    .o-footer-ABJ--logo {
        display: block;
        margin: 0 auto;
        width: 66px;
        height: 55px;
        background: url(/louis/sp/img/common/logo-ABJ.svg) no-repeat;
        background-size: 66px 55px;
    }

    .o-footer-ABJ--message {
        padding: 0 20px;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
    }
}
