// SPヘッダ 左側ハンバーガーメニュー内分離
@import "../../atoms/arrow-mixin";
@import "../../atoms/z-index";
@import "../../templates/layout/mixin";
@import '../../templates/layout/ttsk_mixin';


@keyframes menuShowLeft {
    from {
        left: -100%;
    }
    to {
        left: 0;
    }
}

@keyframes menuHideLeft {
    from {
        left: 0;
    }
    to {
        left: -100%;
    }
}

.o-header-menu {
    float: left;
}

.o-header-menu-btn-wrapper {
    width: 44px;
    height: 44px;
    margin-right: 4px;
    @include mq-up('md') {
        margin-right: 6px;
    }
}

.o-header-menu-content {
    padding: 58px 16px 10px;
    left: -100%;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    overflow-y: auto;
    z-index: $header-content-z-index;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    @include mq-up('md') {
        max-width: 375px;
    }

    .o-header-menu-content-top {
        position: fixed;
        padding: 4px;
        top: 0;
        left: -100%;
        height: 48px;
        width: 100%;
        z-index: $header-content-top-z-index;
        text-align: center;
        background: #fff;
        border-bottom: 1px solid #eef0f2;
        @include mq-up('md') {
            max-width: 375px;
        }
        .o-header-menu-content-top__title {
            font-size: 16px;
            font-weight: bold;
            padding: 8px 0;
        }
        .o-header-menu-content-top-R {
            margin: 0 3px 0;
            position: absolute;
            top: 2px;
            right: 10px;
        }
    }
    &.o-header-menu-content--on {
        display: block;
        animation: menuShowLeft .2s 0s ease-in-out;
        animation-fill-mode: forwards;
        .o-header-menu-content-top {
            display: block;
            animation: menuShowLeft .2s 0s ease-in-out;
            animation-fill-mode: forwards;
        }
    }
    &.o-header-menu-content--off {
        animation: menuHideLeft .2s 0s ease-in-out;
        animation-fill-mode: forwards;
        .o-header-menu-content-top {
            animation: menuHideLeft .2s 0s ease-in-out;
            animation-fill-mode: forwards;
        }

    }
    // ttskのcommonでaタグが変わってしまうので上書きしちゃう
    a {
        color: #1d4a6d;
        text-decoration: none;
    }
}


.o-header-menu-btn-box {
    overflow: hidden;
    padding: 15px 0 0;
    display: flex;
    flex-wrap: wrap;
}

.o-header-menu-btn-box li {
    width: 50%;
    margin-bottom: 15px;
}

// 二個並ぶので50%よりは縮める
.o-header-menu-btn-box li + li {
    margin-left: 15px;
    width: 40%;
    width: calc(50% - 15px);
}

// 会員登録ボタンを伸ばす
.o-header-menu-btn-box li.o-header-left-menu-sign-in {
    width: 100%;
    margin-left: 0;
}

// 下部のリスト
.o-header-menu-info-box {
    //padding: 0 10px;

    a {
        display: flex;
        position: relative;
        text-align: left;
        padding: 9px 30px 9px 15px;
    }

    li a:after {
        @include right-arrow($base-color);
        width: 10px;
        height: 10px;
    }

    .ico {
        font-size: 14px;
        line-height: 1.5;
        vertical-align: middle;
    }

    .ico + .ico-txt,
    .ico-symbol + .ico-txt {
        padding-left: 8px;
        font-size: 95%;
        line-height: 1.5;
    }

    .ico-symbol {
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .symbol-manage-accounts {
        &::before {
            font-family: 'Material Symbols Outlined';
            font-size: 20px;
            font-variation-settings:
                'FILL' 1,
                'wght' 400,
                'GRAD' 0,
                'opsz' 24;
            content: '\f02e';
        }
    }
}

.o-header-menu--notice {
    padding-left: 15px;
    padding-top: 5px;
}

// SPヘッダ左メニュー コイン領域
.o-header-left-menu-coin-box {
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0 5px 23px;
}

.o-header-left-menu-coin-box-balance-coin {
    display: block;
    text-align: left;
}

.o-header-left-menu-coin-box-balance-txt {
    font-size: 12px;
    font-weight: 700;

    .ico-coin {
        vertical-align: middle;
        margin-right: 5px;
    }

    .o-header-left-menu-coin-box--num {
        font-size: 13px;
        margin-right: 5px;
        text-decoration: underline;
    }
}

.o-header-left-menu-coin-box-status-coin {
    font-size: 10px;
    margin-left: 2em;

    span {
        color: $strong-red;
        font-weight: 700;
    }
}

.o-header-left-menu-coin-box-coin-link {
    display: flex;
    align-items: center;
    position: relative;
    padding: 5px 20px 5px 10px;
    border: 1px solid #ccc;
    border-radius: 24px;
    height: 38px;
    font-size: 11px;
    font-weight: 600;

    &:after {
        @include right-arrow($base-color);
        right: 8px;
    }
}


// SPヘッダ左メニュー ログイン後ユーザーメニュー
.o-header-left-menu-tile-box {
    overflow: hidden;
    margin: 0 5px;
}

.o-header-left-menu-tile-box li a {
    padding: 10px 0;
}

.o-header-left-menu-tile-box li {
    float: left;
    width: 33.333%;
    border-top: 1px solid $border-color;
    border-left: 1px solid $border-color;
    box-sizing: border-box;
    margin-top: -1px;
    margin-left: -1px;
}

.o-header-menu-bottom-box {
    overflow: hidden;
    padding: 10px 10px 5px;
    margin-left: auto;
    width: 50%;
    min-width: 155px;

    li {
        float: left;
        margin-right: 4%;
        width: 48%;
        min-width: 62px;

        &:last-child {
            margin-right: unset;
        }
    }


    %a-header-btn-base {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 3px 2px;
        height: 44px;
        border-radius: 4px;
        border: 1px solid $border-color;

        .ico {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding-bottom: 3px;
            font-size: 22px;

            + .ico-txt {
                padding-top: 0;
            }
        }
    }

    .a-header-my-page-btn {
        @extend %a-header-btn-base;
    }
}

.o-header-menu-bottom-box li {
    float: left;
    margin-right: 4%;
    width: 48%;
    min-width: 62px;

    .a-header-logout-btn {
        @extend %a-header-btn-base;

        .ico-loginout {
            font-size: 20px;
        }
    }
}

.o-header-menu-bottom-app-box {
    width: 25%;
    min-width: 60px;

    li {
        width: 100%;
    }
}

// ランク関連複雑なので分離
@import "header-left-menu-rank-box";

.o-header-menu-subscription-box {
    margin: 10px 0 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.o-header-menu-subscription-ttl {
    padding: 5px 13px;
    position: relative;
    line-height: 28px;
    color: $sbsc-base-link-color;
    cursor: pointer;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 600;

    .ico {
        font-size: 22px;
        vertical-align: middle;
        margin-right: 10px;
    }

    &:after {
        @include down-arrow($base-color);
        right: 15px;
        margin-top: -2px;
    }
}

.o-header-menu-subscription-links {
    display: none;
}

.o-header-menu-subscription-box--on {
    .o-header-menu-subscription-ttl {
        // 区切り線
        &:before {
            content: "";
            display: block;
            @include centering(x);
            bottom: 0;
            width: calc(100% - 16px);
            border-bottom: 1px solid $border-color;
        }

        &:after {
            transform: translateY(0) rotate(135deg);
        }
    }

    .o-header-menu-subscription-links {
        display: block;
    }

    a {
        display: block;
    }
}

.o-header-menu-subscription-item {
    position: relative;
    line-height: 26px;
    font-size: 11px;
    font-weight: 600;

    // 区切り線
    &:before {
        content: "";
        display: block;
        @include centering(x);
        bottom: 0;
        width: calc(100% - 16px);
        border-bottom: 1px dotted $border-color;
    }

    &:after {
        @include right-arrow($base-color);
        width: 10px;
        height: 10px;
    }

    &:last-child {
        // 区切り線
        &:before {
            content: none;
        }
    }

    a {
        padding: 5px 0 5px 23px;
    }
}

.o-header-menu-subscription-favorites {
    line-height: 26px;
    font-weight: unset;

    a {
        padding-left: 38px;
    }

    .ico {
        font-size: 10px;
        margin-right: 5px;
        color: $page-link-triangle-color;
    }

    &:after {
        display: none;
    }
}




