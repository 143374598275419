@charset "UTF-8";

@import "../../atoms/colors";
@import "../../atoms/buttons/official-btn";
@import "../../molecules/official-btn-list";
@import "footer-ABJ";

@mixin verticalBarSpacer {
    &::after {
        content: '｜';
    }
    &:last-child {
        &::after {
            display: none;
        }
    }
}

// SPレイアウトフッター
.o-footer {
    padding: 10px 0;
    background: #fff;

    .o-footer-link {
        margin: 0 20px 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .o-footer-link-item {
        margin-top: 12px;
        font-size: 11px;
        @include verticalBarSpacer;
    }

    .o-footer-mode {
        text-align: center;
        margin: 12px 0;
    }

    .o-footer-mode-title {
        margin-right: 1rem;
    }

    .o-footer-mode-link {
        @include verticalBarSpacer;

        > a {
            text-decoration: underline;
        }
    }

    // 取扱い商品数、出版社数
    .o-footer-bookcount {
        font-size: 13px;
        font-weight: bold;
        margin: 0 20px 0;
        text-align: center;
    }

}

.o-footer-copyright {
    background: $base-color;
    color: #fff;
    text-align: center;
}
