// タテスクテキスト付きトグルボタン
@import "../../atoms/colors";
@import "../../atoms/z-index";
@import "../../atoms/buttons/ttsk-toggle-btn";
@import "../../templates/layout/ttsk_mixin";


// ==============================================
// スタイル
// ==============================================
.m-ttsk-toggle-btn-with-txt {
    cursor: pointer;
    border-bottom: 1px solid $ttsk-border-color;
    padding: 16px;
    height: 60px;
    display: flex;
    justify-content: space-between;

    .m-ttsk-toggle-btn-with-txt__txt {
        font-size: 12px;
        font-weight: bold;
        color: $ttsk-text-color;
        padding: 5px 0;
    }
}
