@charset "UTF-8";

@import "colors";
@import "z-index";
@import "../templates/layout/mixin";

// タグ
// ジャンル・商品情報・登録状況など

// 特典・セット・完結
@mixin tags-base {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 14px;
    border: 1px solid transparent;
    font-size: 10px;
    font-weight: 700;
    color: #fff;
    color-scheme: only light;
    line-height: 1;
    background: #fff;
    box-sizing: border-box;
    overflow: hidden;
}

// フリーサイズ（電子書籍・試読増量）
@mixin tags-base--free {
    @include tags-base;
    width: unset;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

// ジャンル分類タグ用（角丸）
@mixin genre-tags {
    @include tags-base;
    border-radius: 4px;
    border: none;
    font-size: 10px;
}

// マンガ作品タグ
.a-tag-comic {
    @include genre-tags;
    background: $manga-key-color;
}

// ライトノベル作品タグ
.a-tag-LN {
    @include genre-tags;
    background: $light-novel-key-color;
}

// R18作品タグ
.a-tag-r18 {
    @include genre-tags;
    background: $r18-key-color;
}

// 文芸作品タグ
.a-tag-literature {
    @include tags-base;
    box-sizing: border-box;
    border-radius: 4px;
    border-color: $weak-gray;
    color: $weak-gray;
}

// 話連載のタグ
.a-tag-warensai {
    @include tags-base;
    width: 48px;
    padding: 0 3px;
    box-sizing: border-box;
    border-radius: 4px;
    border-color: $weak-gray;
    color: $weak-gray;
}

// マンガ・ラノベ・文芸以外のタグ
// 文芸と同じデザイン。一応分ける
// auto width, do not fix tag text length
.a-tag-other {
    @include tags-base;
    width: auto;
    min-width: 38px;
    padding: 0 3px;
    box-sizing: border-box;
    border-radius: 4px;
    border-color: $weak-gray;
    color: $weak-gray;
}

// 特典タグ
.a-tag-gift {
    @include tags-base;
    color: $gift-color;
    border-color: $gift-color;
}

// セット商品タグ
.a-tag-set {
    @include tags-base;
    color: $set-color;
    border-color: $set-color;
}

// 完結タグ
.a-tag-comp {
    @include tags-base;
    color: $base-link-color;
    border-color: $base-link-color;
}

// 電子書籍
.a-tag-ebook {
    @include tags-base--free;
    color: $weak-gray;
    border-color: $border-color;
}

// デジタルコンテンツ
.a-tag-digital {
    @include tags-base--free;
    color: $weak-gray;
    border-color: $border-color;
}

// 最新刊
.a-tag-latest {
    @include tags-base--free;
    color: $weak-gray;
    border-color: $border-color;
}

// 試読増量
.a-tag-increase {
    @include tags-base--free;
    color: $increase-color;
    border-color: $increase-color;
}

// 読み放題
.a-tag-subscription {
    @include tags-base--free;
    position: relative;
    padding-left: 22px;
    border: none;
    color: #fff;
    background: $sbsc-tag-color;

    &::before {
        content: "";
        position: absolute;
        top: 1px;
        left: 4px;
        width: 15px;
        height: 12px;
        background-image: url(/anne/img/common/icon-sbsc-i.svg);
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
    }
}

// リスト用：NEWタグ
.a-tag-new {
    @include tags-base;
    color: $sale-color;
    background: $new-tag-color;
}

// リスト用：予約タグ
.a-tag-reserve {
    @include tags-base;
    color: #fff;
    background: $strong-blue;
}

// リスト用：SALEタグ
.a-tag-sale {
    @include tags-base;
    color: #fff;
    background: $sale-color;
}

// リスト用：コインアップタグ
.a-tag-coin-up {
    @include tags-base;
    justify-content: left;
    position: relative;
    width: 48px;
    padding-left: 3px;
    background: $coin-up-tag-color;

    &::before, &::after {
        content: '';
        display: inline-block;
        position: absolute;
        margin-top: -1px;
    }

    &::before {
        top: 6px;
        right: 14%;
        width: 5px;
        height: 5px;
        background: #fff;
        transform: rotate(135deg);
    }

    &::after {
        top: 3px;
        right: 4px;
        border-style: solid;
        border-width: 0 8px 8px 0;
        border-color: transparent #fff transparent transparent;
    }
}

// リスト用：めがイラストタグ
.a-tag-mega-illust {
    @include tags-base;
    width: auto;
    background: #fff;
    border-color: $base-color;
    color: $base-color;
    padding-left: 4px;
    padding-right: 4px;
}

// 購入・既読情報表示タグ（購入済み・登録済み・未読・既読）----
@mixin status-tags-block {
    padding: 0 5px 1px;
    height: 19px;
    box-sizing: border-box;
    border: 1px solid $border-color;
    border-radius: 4px;
    background: #fff;
    color-scheme: only light;
    line-height: 19px;

    .ico {
        display: inline-block;
        font-size: 10px;
        vertical-align: middle;
    }

    .ico-txt {
        display: inline-block;
        font-size: 10px;
        font-weight: 700;
    }
}

// 購入済み
.a-tag-purchased {
    @include status-tags-block;
    color: $active-text-color;
}

// 登録済み
.a-tag-registered {
    @include status-tags-block;
    background: $status-tag-bg-color;
    color: $weak-gray;
}

// 未読
.a-tag-unread {
    @include status-tags-block;
    color: $active-color;
}

// 既読
.a-tag-already-read {
    @include status-tags-block;
    background: $status-tag-bg-color;
    color: $weak-gray;

    .ico {
        font-size: 14px;
    }
}


// 絞り込み用-------------------------------------

// 設定中タグ
.a-tag-setting-up {
    display: block;
    position: absolute;
    top: calc(50% - 7px);
    right: 10px;
    height: 12px;
    padding: 0 8px;
    border-radius: 8px;
    font-size: 9px;
    vertical-align: middle;
    background: $menu-badge-color;
    color: #fff;
}

// 予約でコインUP!
.a-tag-reserve-coin-up {
    @include tags-base--free;
    border-color: $strong-red;
    background: #fff;
    color: $strong-red;

    &.a-tag-reserve-coin-up-more {
        color: #fff;
        background: $strong-red;
    }
}

// 予約でコインUP!
.a-tag-limit {
    @include tags-base--free;
    border-color: $strong-red;
    background: #fff;
    color: $strong-red;
}
