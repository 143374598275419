@charset "UTF-8";

@import "../../../atoms/colors";
@import "../../../templates/layout/font-variable";
@import "../../../molecules/ttsk/ttsk-ticket-info";
@import "../../../molecules/ttsk/ttsk-header-left-menu-ticket-coin";

// 話連載ヘッダ左メニュー チケット表示
.o-ttsk-header-left-menu-ticket-coin-box {
    margin: 24px 16px 16px 16px;
    background-color: #EEEEEE;

    .o-ttsk-header-left-menu-ticket-coin-list__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 16px;
        font-size: 12px;

        &:not(:last-child) {
            border-bottom: 1px solid #fff;
        };

        .m-ttsk-ticket-item {
            font-size: 14px;
            font-weight: $font-bold;

            .image-ticket {
                width: 26px;
                margin-right: 1px;
            }
        }
    }

    // 注意文言
    .m-ttsk-ticket-info__box-annotation {
        padding: 14px 16px;
        border-top: 1px solid #fff;
        li {
            color: $ttsk-light-text-color;
            line-height: 1.5;
            font-size: 10px;
            text-align: center;
            &::before {
                content: "※";
            }
        }
    }

}
