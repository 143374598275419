@charset "utf-8";
// タテスククローズボタン

// ============================
// 読み込み
// ============================
@import "../colors";
@import "../../templates/layout/ttsk_mixin";

// ==============================================
// スタイル
// ==============================================
//クローズボタン
.a-ttsk-btn-close {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 48px;
    height: 48px;
    cursor: pointer;
    .material-icons {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        border: 1px solid #FF8F66;
        background-color: #FDEDE4;
        font-size: 24px;
        line-height: 1.3;
        text-align: center;
        color: #AA3B13;
        @include baseTransition;
        @include mq-up('md') {
            width: 24px;
            height: 24px;
            border-radius: 12px;
            font-size: 16px;
            line-height: 1.4;
        }
    }
    &:hover {
        @include mq-up('md') {
            opacity: 1;
            .material-icons {
                background-color: #FBBF9D;
                opacity: 1;
            }
        }
    }
}