@charset "UTF-8";

// ローディング用のアニメーション設定
.a-loading-animation {
    display: inline-block;
    width: 80px;
    height: 80px;
    background: url("/anne/img/common/loading-animation.svg") no-repeat;
    background-size: 80px 80px;
    animation: loading .7s linear infinite;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}