// タテスクヘッダー用所持コイン情報
@import "../../templates/layout/font-variable";
@import "../../atoms/colors";

.m-ttsk-header-menu-coin-area {
    padding-left: 5px;

    @include mq-up('lg') {
        padding-left: 4px;
    }

    .m-ttsk-header-menu-coin-link {
        display: block;
    }

    .m-ttsk-coin-item--small-link,
    .m-ttsk-point-item--small-link {

        +.m-ttsk-header-menu-coin-expire {
            margin-top: 2px;
        }
    }

    .m-ttsk-header-menu-coin-expire {
        color: $base-color;
        font-size: 10px;
        line-height: 1.2;

        .m-ttsk-header-menu-coin-expire-text {
            display: flex;
        }

        .m-ttsk-header-menu-coin-expire-month {
            margin-right: 1px;
        }

        .m-ttsk-header-menu-coin-expire-price {
            font-weight: $font-bold;
            color: $strong-red;
        }
    }
}
