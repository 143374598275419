@import "../../atoms/colors";

// SNS公式アカウントへのリンクボタン（丸形）
@mixin official-btn-base {
    display: block;
    width: 44px;
    height: 44px;
    color-scheme: only light;
}

// BW公式X（旧Twitter）
.a-official-x-btn {
    @include official-btn-base;
    background: url("/anne/img/common/logo_circle_x.svg") no-repeat;
}

// BW公式Facebook
.a-official-facebook-btn {
    @include official-btn-base;
    background: url("/anne/img/common/logo_circle_facebook.svg") no-repeat;
}

// BW公式LINE＠
.a-official-line-btn {
    @include official-btn-base;
    background: url("/anne/img/common/logo_circle_line.svg") no-repeat;
}

// BW公式tumblr
.a-official-tumblr-btn {
    @include official-btn-base;
    background: url("/anne/img/common/logo_circle_tumblr.svg") no-repeat;
}

// メールマガジン
.a-official-mail-magazine-btn {
    @include official-btn-base;
    background: url("/anne/img/common/logo_circle_bw-mail-magazine.svg") no-repeat;
}
