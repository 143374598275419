@charset "UTF-8";

@import "../../templates/layout/mixin";
@import "../../../../../bob/fonts/style";
@import "../../atoms/z-index";
@import "../../atoms/buttons/header-buttons";
@import "../../atoms/buttons/ttsk-btn";
@import "../../molecules/ttsk/ttsk-ticket-item";
@import "../../molecules/ttsk/ttsk-coin-point-item";
@import "../../molecules/ttsk/ttsk-header-coin-item";

// TODO:後で修正
@import "../../molecules/reserve-modal";

// 各パーツinclude
@import "../../molecules/header-message-popup";
@import "header-left-menu";
@import "ttsk/ttsk-header-left-menu";
@import "header-search-menu-content";
@import "ttsk/ttsk-header-search-menu-content";

//2022/10過ぎたら消す（話連載チケット→コイン変更時のお知らせ）
@import "../../molecules/ttsk/ttsk-header-info";

// SP共通レイアウトヘッダー
.o-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    color: #333;
    word-break: break-all;
    z-index: $header-z-index;
    overflow-wrap: break-word;
    @include baseTransition;

    &.o-header--on {
        z-index: $header-on-z-index;
    }

    .o-header-inner {
        position: relative;
        background: #fff;
        border-bottom: 1px solid #DAE6F1;
        font-family: $base-font-family;
        height: 94px;
        line-height: 1.5;
        margin: 0;
    }

    // ヘッダーの上段
    .o-header-headerTop {
        padding: 2px 0;
        border-bottom: 1px solid $base-color;
        @include mq-up('md') {
            padding: 0 0 5px;
        }

        .o-header-headerTop__inner {
            max-width: 1080px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 auto;
            padding: 0 16px 0 3px;
            @media (max-width: (330px)) {
                padding: 0 8px 0 0;
            }
            @include mq-up('lg') {
                padding-right: 0;
            }
        }
        .o-header-headerTop-L {
            display: flex;
            align-items: center;
        }
        .o-header-headerTop-L_strict-store {
            height:44px;
            padding-left: 10px;
        }

        .o-header-switch-service {
            display: flex;
            align-items: center;
            overflow: hidden;
            .o-header-switch-service-name {
                display: block;
                position: relative;
                border-radius: 25px;
                color: #1d3850;
                font-size: 12px;
                font-weight: bold;
                padding: 4px 8px;
                text-align: center;
                @include baseTransition;
                @include mq-up('md') {
                    padding: 4px 16px;
                }
            }
            .o-header-switch-service-name__on {
                @extend .o-header-switch-service-name;
                color: #fff;
                background-color: #1d3850;
            }
            li {
                @include baseTransition;
                &:not(:last-child) {
                    margin-right: 4px;
                    @include mq-up('md') {
                        margin-right: 16px;
                    }
                }
                &:hover {
                    .o-header-switch-service-name {
                        @include mq-up('md') {
                            color: #fff;
                            background-color: #1d3850;
                        }
                    }
                }
            }
        }

        .o-header-switch-service-arrow-wrapper {
            position: absolute;
            &.tanko {
                width: 50px;
                @include mq-up('md') {
                    width: 70px;
                }
            }
            &.warensai {
                width: 60px;
                @include mq-up('md') {
                    width: 80px;
                }
            }
            &.yomihodai {
                width: 60px;
                @include mq-up('md') {
                    width: 80px;
                }
            }
        }

        .o-header-switch-service-arrow {
            @include centering(x);
            bottom: -4px;
            @include mq-up('md') {
                bottom: -6px;
            }

            // 上三角
            &::before, &::after {
                content: "";
                @include centering(x);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 8px 8px 8px;
                border-color: transparent transparent $base-color transparent;
                z-index: 1;
                margin: auto;
            }

            // 上三角
            &::after {
                margin-top: 1px;
                border-color: transparent transparent #fff transparent;
                z-index: 2;
            }

        }

        // firefoxのみ適用
        @-moz-document url-prefix() {
            .o-header-switch-service-arrow  {
                bottom: -3px;
            }
        }

    }

    // ヘッダーの下段
    .o-header-headerBtm {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1080px;
        margin: 0 auto;
        padding: 0 5px;

        @media screen and (min-width: 374.98px) {
            padding: 0 10px;
        }

        @include mq-up('lg') {
            padding: 0;
        }

        .o-header-nav-inner,
        .o-header-nav-inner_warensai {
            display: flex;
        }

        .o-header-nav-inner > li {
            display: block;
            min-width: 38px;
            @media screen and (min-width: 361px) {
                min-width: 44px;
            }
            &:not(:last-child) {
                @include mq-up('lg') {
                    margin-right: 10px;
                }
            }
        }
        .o-header-nav-inner_warensai > li {
            min-width: 46px;
            padding: 0 2px;
            box-sizing: content-box;

            @include mq-up('xs') {
                padding: 0 3px;
            }

            + li {
                @include mq-up('md') {
                    margin-left: 4px;
                }
            }

            &:last-child {
                margin-right: 2px;

                @include mq-up('xs') {
                    margin-right: 3px;
                }
            }
        }

        // コインエリア
        .o-header-menu-coin-area {
            display: flex;
            align-items: center;

            .o-header-menu-rank {
                margin: 8px 0;
            }
        }

        .iconRank {
            display: block;
            margin-right: 0;
            text-align: center;
            @media screen and (min-width: 361px) {
                margin-right: 4px;
            }

            &:before {
                width: 30px;
                height: 27px;
                background-image: url(/louis/sp/img/reading-note/common/sprite-rank-sp.png);
                background-repeat: no-repeat;
                background-size: 60px auto;
                content: '';
                display: inline-block;
            }

            // ランクなしのみ強制ダークを当てる
            &:not(.noRank) {
                &:before {
                    color-scheme: only light;
                }
            }

            &.noRank:before {
                content: "";
                width: 22px;
                height: 17px;
                margin-top: 0.5px;

                background: url(/anne/img/common/header/icon_norank.svg) no-repeat center/contain;
                vertical-align: -4px;

                @media screen and (max-width: 360px) {
                    margin-right: 2px;
                }
            }

            &.joker:before {
                background-position: 50% -684px;
                vertical-align: -7px;

                @media (prefers-color-scheme: dark) {
                    background-color: #fff;
                }
            }

            &.ace:before {
                background-position: 50% -658px;
                vertical-align: -7px;

                @media (prefers-color-scheme: dark) {
                    background-color: #fff;
                }
            }

            &.king:before {
                background-position: 50% -631px;
                vertical-align: -7px;
            }

            &.queen:before {
                background-position: 50% -601px;
                vertical-align: -7px;
            }

            &.jack:before {
                background-position: 50% -574px;
                vertical-align: -7px;
            }

            &.regular:before {
                background-position: 50% -547px;
                vertical-align: -7px;
            }

            &.light:before {
                background-position: 50% -521px;
                vertical-align: -7px;
            }
        }

        .o-header-menu-balance {
            align-self: center;
            text-align: left;
            .balanceCoin {
                color: #2f2f2f;
                font-size: 10px;
            }
            .balanceCoin-digit {
                font-size: 12px;
                font-weight: bold;
                margin-right: 2px;
            }
        }

        .o-header-menu-expire {
            position: relative;
            background-color: #fff;
            margin-top: 2px;
            line-height: 1.2;
            text-align: center;

            .expireCoin {
                color: #e40007;
                font-size: 10px;
                font-weight: bold;
                &:before {
                    content: '└';
                    color: #231815;
                    font-weight: bold;
                }
            }
            .expireCoin-month {
                color: #2f2f2f;
                font-size: 10px;
                font-weight: 400;
                margin-right: 1px;
            }

            .expireCoin-digit {
                display: inline-block;
            }
        }


    }


    a, input, select {
        -webkit-tap-highlight-color: rgba(123, 200, 206, .3);
    }

    &.o-header--off {
        transform: translateY(-100%);
    }

}


.o-header-overlay {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .6);
    z-index: $header-overlay-z-index;
}

.o-header-overlay--on {
    visibility: visible;
}


// メッセージ用アニメーション
@keyframes popupBalloon {
    0% {
        visibility: hidden;
        opacity: 0;
    }
    1% {
        visibility: visible;
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    85% {
        opacity: 1;
    }
    99.9% {
        visibility: hidden;
        opacity: 0;
    }
    100% {
        visibility: hidden;
        opacity: 0;
    }
}
// TODO これは後で書き換えるやつ
// SPヘッダのメッセージポップアップ
.m-header-message-popup {
    position: absolute;
    top: 98px;
}

// ボタンスタイルの上書き
.a-ttsk-btn--secondary {
    padding: 4px 10px;
    @include mq-up('md') {
        font-size: 16px;
        padding: 4px 12px;
    }
}

// ui-autocompleteのサジェストメニューの幅を強制的に狭くする
.ui-menu {
    max-width: calc(100% - 34px);
    @include mq-up('md') {
        max-width: 340px;
    }
}
