@charset "utf-8";
// タテスクアイコン

// ============================
// 読み込み
// ============================
@import "anne/scss/sp/atoms/colors";
@import "../../templates/layout/ttsk_mixin";

// ==============================================
// スタイル
// ==============================================
// google Material icons
// ==============================================
// google Material iconsアウトラインのベース
@mixin ttsk-icon-base-outline {
    &::before {
        font-family: 'Material Icons Outlined';
        display: inline-block;
    }
}

// google Material icons塗りつぶしのベース
@mixin ttsk-icon-base-filled {
    &::before {
        font-family: 'Material Icons';
        display: inline-block;
    }
}

// 単行本トップアイコン
.a-ttsk-icon--store {
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        background-image: url(/anne/img/ttsk/icon-store.svg);
    }
}


// 読み放題トップアイコン
.a-ttsk-icon--read {
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 10px;
        background-image: url(/anne/img/ttsk/icon-read.svg);
    }
}

// インフォメーション（iアイコン）
.a-ttsk-icon--info {
    @include ttsk-icon-base-outline;
    color: $ttsk-blue-gray-color;
    &::before {
        content: '\e88e';
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
    }
}

// インフォメーション（?アイコン）
.a-ttsk-icon--help {
    @include ttsk-icon-base-outline;
    color: $ttsk-blue-gray-color;
    &::before {
        content: '\e8fd';
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
    }
}

.a-ttsk-icon--logout {
    @include ttsk-icon-base-outline;
    color: $ttsk-blue-gray-color;
    &::before {
        content: '\e9ba';
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
    }
}

.a-ttsk-icon--search {
    @include ttsk-icon-base-outline;
    color: $ttsk-blue-gray-color;
    &::before {
        content: '\e8b6';
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
    }
}

.a-ttsk-icon--clear {
    @include ttsk-icon-base-outline;
    color: $ttsk-blue-gray-color;
    &::before {
        content: '\e14c';
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
    }
}

.a-ttsk-icon--subscription {
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
        content: '';
        display: inline-block;
        width: 19px;
        height: 14px;
        margin: 0 4px 0 0;
        background: url('/anne/img/ttsk/icon-subscription.svg') center center/contain no-repeat;
    }
}

// チェックマークアイコン
.a-ttsk-icon--check {
    @include ttsk-icon-base-filled;
    color: $ttsk-dark-blue-color;
    &::before {
        content: "\e5ca";
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
    }
}

// ４色のボーダーアイコン（セクションにborder-topが必要）
// ==============================================
.a-ttsk-border-icon {
    display: block;
    position: relative;
    @include mq-up('lg'){
        max-width: 1080px;
        margin: 0 auto;
        padding: 0 16px;
    }
    &::before {
        content:"";
        width: 32px;
        height: 3px;
        position: absolute;
        top: -3px;
        left: 0;
        background-image: linear-gradient(90deg, $ttsk-blue-color 0, $ttsk-blue-color 8px, $ttsk-yellow-color 8px, $ttsk-yellow-color 16px, $ttsk-green-color 16px, $ttsk-green-color 24px, $ttsk-red-color 24px, $ttsk-red-color 32px);		background-repeat: no-repeat;
        @include mq-up('md'){
            width: 64px;
            height: 6px;
            top: -6px;
            background-image: linear-gradient(90deg, $ttsk-blue-color 0, $ttsk-blue-color 16px, $ttsk-yellow-color 16px, $ttsk-yellow-color 32px, $ttsk-green-color 32px, $ttsk-green-color 48px, $ttsk-red-color 48px, $ttsk-red-color 64px);
            background-repeat: no-repeat;
        }
    }
}
