// SEO対策にページフッターにリンクをはる。
// ほぼ既存のご利用ガイドのまるコピデザインだが、CSS的に一応分離する
@import "../atoms/colors";

.t-filter-seo-links {
    .ico-txt {
        font-size: 1.6rem;
        vertical-align: middle;
        padding-left: 5px;
    }

    background: #fff;
    border-top: 10px solid $background-color;
    border-bottom: 10px solid $background-color;
}

.t-filter-seo-links ul {
    overflow: hidden;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
}

.t-filter-seo-links ul li {
    float: left;
    width: 50%;
    border-top: 1px solid $border-color;
    border-left: 1px solid $border-color;
    margin-top: -1px;
    margin-left: -1px;
    box-sizing: border-box;

    &:last-child:not(:nth-child(even)) {
        width: 100%;
    }
}

.t-filter-seo-links ul li a {
    position: relative;
    display: block;
    line-height: 1.5;
    padding: 13px 10px;
    min-height: 44px;
    max-height: 44px;
    box-sizing: border-box;

    &:after {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        right: 15px;
        width: 6px;
        height: 6px;
        border-right: 2px solid $arrow-color;
        border-bottom: 2px solid $arrow-color;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        margin-top: -3px;
    }
}

.t-filter-seo-links-heading {
    padding: 4px 10px 8px;
    background: $base-color;
    color: #fff;
    line-height: 24px;
    box-sizing: border-box;
}
