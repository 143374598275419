@charset "UTF-8";

@import "../../atoms/colors";
@import "../../molecules/official-btn-list";
@import "../../templates/layout/mixin";

// footer-beginner-guideに代わる新たなフッターのガイドコンテンツ

.o-guide-wrap {
    margin: 0 0 20px 0;
    padding: 0 0 12px 0;
    border-top: 2px solid $base-color;
    background: #fff;

    // BOOK☆WALKERの楽しみ方
    .o-guide-box {
        border-top: 2px dotted $border-color;

        &:first-child {
            border: none;

            .o-guide-ttl {
                &:first-child {
                    margin-top: 16px;
                }
            }
        }

        .o-guide-ttl {
            height: 24px;
            margin: 24px 16px 12px;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            color: $base-color;

            &::before {
                content: '';
                display: inline-block;
                width: 4px;
                height: 24px;
                margin-right: 8px;
                vertical-align: top;
                background: $base-color;
            }
        }

        .o-guide-bnr-group-wrap {
            padding: 0 16px;

           > * {
               &:first-of-type {
                   margin-top: 24px;
                   padding-top: 0;
                   border-top: none;
               }

                &:last-of-type {
                    border-bottom: none;
                }
            }
        }

        // バナーグループ（PC横並び）
        .o-guide-bnr-group--list {
            margin: 24px 0;

            .o-guide-bnr-group-inner {
                display: grid;
                grid-auto-flow: row;
                grid-gap: 24px 16px;
                max-width: 1048px;
                margin: 0 auto;

                @media (min-width: 768px) {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }

        // バナーグループ（登録周り）
        .o-guide-bnr-group--register {
            padding: 24px 0;

            border-top: 2px dotted $border-color;
            border-bottom: 2px dotted $border-color;

            .o-guide-bnr-group-inner {
                @media (min-width: 768px) {
                    max-width: 1048px;
                    margin: 0 auto;
                }
            }

            .m-guide-bnr-box {
                @media (min-width: 768px) {
                    max-width: none;
                }

                .m-guide-bnr-link {
                    text-align: center;

                    @media (min-width: 768px) {
                        background-color: #fef260;
                    }
                }
            }
        }

        // BWの楽しみ方
        .m-guide-bnr-box {
            margin: 0 auto;
            max-width: 450px;

            .m-guide-bnr-txt {
                margin-bottom: 8px;
                font-size: 13px;
                font-weight: 700;
            }

            .m-guide-bnr-link {
                display: block;

                @include hover-pc {
                    opacity: 0.7;
                }

                img {
                    max-width: 100%;
                    height: auto;
                    vertical-align: middle;
                }

            }
        }

        // 無料で会員登録
        .m-guide-register-box {
            margin: 12px auto 0 auto;
            max-width: 290px;

            .m-guide-register-txt {
                margin-bottom: 8px;
                font-size: 12px;
                font-weight: 700;
                text-align: center;
            }
        }

        // ご利用ガイド
        .o-guide-usage-list {
            display: grid;
            grid-auto-columns: 40px;
            grid-template-columns: repeat(auto-fit, 152px);
            justify-content: center;
            width: 95%;
            margin: 16px auto;

            li {
                margin: 8px;

                .o-guide-usage-list-link {
                    display: inline-block;
                    padding: 8px;
                    font-size: 12px;
                    font-weight: 700;

                    &::before {
                        content: '';
                        display: inline-block;
                        width: 0;
                        height: 0;
                        margin-right: 4px;
                        border-style: solid;
                        border-width: 5px 0 5px 8px;
                        border-color: transparent transparent transparent $page-link-triangle-color;
                    }
                }
            }

            // タブレット以上の時は横並び
            @include tab {
                display: block;

                li {
                    display: inline-block;
                }
            }
        }

        // アプリダウンロード
        .o-guide-app {
            margin: 16px 32px;
            font-size: 0;
            text-align: center;

            li {
                display: inline-block;
                width: 45%;
                width: calc(calc(100% - 32px) / 2);
                max-width: 160px;
                margin: 4px 8px;

                .o-guide-app-txt {
                    padding: 0 0 0 4px;
                    font-size: 13px;
                    font-weight: 700;
                    text-align: left;
                }

                .o-guide-app-link {
                    display: block;
                    margin: 4px 0 0;

                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}
