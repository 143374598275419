@charset "utf-8";
//タテスクセクション

// ============================
// 読み込み
// ============================
@import "../../templates/layout/ttsk_mixin";
@import "../../atoms/arrow-mixin";
@import "../../atoms/colors";
@import "../../atoms/z-index";

// ==============================================
// スタイル
// ==============================================

// tile
// ==============================================

// 作品名
.o-ttsk-tile__title {
    display: -webkit-box;
    min-height: 36px;
    color: $ttsk-text-color;
    font-size: 12px;
    line-height: 1.5;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
    overflow: hidden;
    text-overflow: clip;
    word-break: break-all;
    &.o-ttsk-tile__title--oneline {
        min-height: 18px;
        -webkit-line-clamp: 1;
    }
}

// 付属情報
.o-ttsk-tile__status,
%o-ttsk-tile__status {
    margin-top: 4px;
    font-size: 10px;
    line-height: 1.5;
    color: $ttsk-light-text-color;
}
// 更新日
.o-ttsk-tile__status--date {
    @extend %o-ttsk-tile__status;
}
.o-ttsk-tile__status--current-date {
    @extend %o-ttsk-tile__status;
    color: $ttsk-red-color;
}
// 購入,未購入話数
.o-ttsk-tile__status--purchased,
.o-ttsk-tile__status--not-purchased, {
    @extend %o-ttsk-tile__status;
    .a-ttsk-tag--purchased,
    .a-ttsk-tag--not-purchased {
        margin-right: 4px;
    }
}
// セール訴求
.o-ttsk-tile__status--sale {
    @extend %o-ttsk-tile__status;
}
// タイルリスト
.o-ttsk-tile__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px 3%;
    max-width: 1080px;
    margin: 0 auto;
    padding: 0 16px;
    @media screen and (min-width: 414px) {
        gap: 24px 4.5%;
    }
    @media screen and (min-width: 521px) {
        grid-template-columns: repeat(6, 1fr);
        gap: 24px 3.34%;
    }
    @include mq-up('md'){
        gap: 40px 3.34%;
    }
    @include mq-up('lg'){
        padding: 0;
    }
    li {
        position: relative;

        .m-ttsk-thumb {
            margin-bottom: 5px;
        }

        .m-ttsk-thumb__image {
            position: relative;
            width: 100%;
            padding-top: 141%;
            img {
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
            }
        }

        a {
            display: block;
        }
    }
}

// 横スクロールするタイプのタイルリスト
.o-ttsk-tile__list--slide {
    display: grid;
    justify-content: space-around;
    grid-template-columns: repeat(6, 1fr);
    gap: 24px 3.34%;
    max-width: 1080px;
    margin: 0 auto;
    @include mq-down('lg'){
        padding: 0 16px;
    }
    @media screen and (max-width: 520px) {
        grid-auto-flow: column;
        grid-template-columns: repeat(auto-fill, 114px);
        justify-content: flex-start;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        gap: 0 10px;
    }

    li {
        position: relative;

        @media screen and (max-width: 520px) {
            width: 114px;
        }

        .m-ttsk-thumb {
            margin-bottom: 5px;
        }

        .m-ttsk-thumb__image {
            position: relative;
            width: 100%;
            padding-top: 141%;
            img {
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
            }
        }
        a {
            display: block;
        }
    }
}
