@charset "UTF-8";
// footer 付近に配置するクーポン

@import "../../../../../anne/scss/sp/atoms/z-index";

@keyframes couponOpen {
    from {
        opacity: 0;
        transform: scale(0, 0);
    }
    to {
        opacity: 1;
        transform: scale(1, 1);
    }
}

.footer-coupon {
    position: fixed;
    bottom: 20px;
    left: 0;
    z-index: $footer-floating-box-z-index;
    transition: all 0.3s;
    opacity: 0;
    display: none;

    &.on {
        display: block;
        opacity: 1;
    }

    .coupon-ico {
        background: #3f2b2a;
        color: #FFF;
        border-radius: 50%;

        .ico {
            display: block;
            text-align: center;
        }
    }

    .coupon-closed {
        position: relative;
        transition: all 0.3s;
        opacity: 0;
        display: none;

        .coupon-btn {
            background-color: #ffe746;
            width: 48px;
            padding-top: 8px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            display: block;
            box-shadow: 1px 1px 8px rgba(64, 64, 64, 0.25);
        }

        &.active {
            display: block;
            opacity: 1;
        }

        .coupon-ico {
            height: 26px;
            width: 26px;
            margin-left: 10px;
            margin-top: 0;

            .ico {
                padding-top: 5px;
                padding-left: 3px;
                font-size: 16px;
            }
        }

        .coupon-txt {
            color: #ff4479;
            font-size: 12px;
            font-weight: bold;
            margin-left: 5px;
        }
    }

    .coupon-opening {
        position: relative;
        margin: 5px;
        background-color: #ffe746;
        border-radius: 10px;
        display: none;
        box-shadow: 2px 2px 8px rgba(64, 64, 64, 0.25);
        transform-origin: left bottom;

        &.active {
            display: block;
            animation: couponOpen 0.5s ease 0s 1;
        }

        &.active.open {
            animation: couponOpen 0.2s ease-in-out 0s 1;
        }

        .coupon-btn {
            font-size: 22px;
            position: absolute;
            right: -20px;
            top: -20px;
            width: 50px;
            height: 50px;
            z-index: $footer-coupon-button-z-index;
        }

        .coupon-ico {
            display: block;
            width: 30px;
            height: 30px;
            margin: 10px auto 0;
            text-align: center;

            .ico {
                padding-top: 5px;
                font-size: 22px;
            }
        }

        .coupon-area {
            a {
                display: block;
            }
        }

        img {
            width: 150px;
            height: auto;
            vertical-align: bottom;
        }
    }
}
