@charset "UTF-8";
@import "../colors";
@import "../z-index";

// 右向き矢印
@mixin right-arrow {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 15px;
    width: 10px;
    height: 10px;
    margin-top: -5px;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
}

// 「選択中の条件を全て解除」ボタン
.a-filter-release-all-btn {
    display: block;
    width: 100%;
    height: 30px;
    border: 1px solid $border-color;
    border-radius: 4px;
    box-shadow: 0 1px $border-color;
    text-align: center;
    line-height: 30px;
    color: $strong-red;

    .ico {
        vertical-align: middle;
    }
}

// 「この条件で再検索」ボタン
.a-re-conditional-search-btn {
    display: block;
    position: relative;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid $border-color;
    font-weight: 700;
    // TODO:ボタン横の矢印マークも共通化できたらする…？
    &::after {
        @include right-arrow;
        border-color: $base-color;
    }
}

// 「再検索」ボタン
.a-re-search-btn {
    display: block;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid $border-color;
    box-shadow: 0 1px $border-color;
    color: $base-color;
    font-weight: 700;
    text-align: center;

    .ico {
        margin: 0 3px 0 0;
        vertical-align: middle;
    }

    .ico-txt {
        font-size: 15px;
    }
}

// 「設定」ボタン
.a-setting-btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 90px;
    height: 38px;
    border-radius: 24px;
    border: 1px solid $border-color;
    box-shadow: 0 1px $border-color;
    font-size: 15px;
    font-weight: 700;
    line-height: 38px;
    text-align: center;
    vertical-align: middle;
    color: $active-text-color;
    background: #fff;
}

// 矢印付きリンク
.a-filter-link {
    position: relative;
    display: block;
    width: 100%;
    height: 36px;
    padding: 0 10px;
    line-height: 36px;
    border-top: 1px dotted $border-color;

    &::after {
        @include right-arrow;
        border-color: $weak-gray;
    }
}