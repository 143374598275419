/*
  フッター上部リストメニュー
  PCのサイドカラムをSPに持って来たやつでも使用
    ↑のためAnneでも使わざるを得ないので一旦分離
 */
.beginnersGuide {
  border-top: 10px solid #eef0f2;
  border-bottom: 10px solid #eef0f2;
  .ico {
    &-beginner {
      font-size: 19px;
      vertical-align: middle;
    }
    &-txt {
      font-size: 16px;
      vertical-align: middle;
      padding-left: 5px;
    }
  }
}
