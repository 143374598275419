@charset "UTF-8";
@import "../atoms/colors";
@import "../atoms/z-index";

// カレンダー（現状絞り込みで使用）
.o-calender {
    display: none;

    &--on {
        display: table;
        width: 100%;
        margin: 0 auto 8px;
        border-collapse: separate;
        border-spacing: 3px;
        font-size: 10px;
        background: #fff;

        thead {
            border-bottom: 1px dotted $border-color;

            tr {
                th {
                    position: relative;
                    height: 30px;
                    vertical-align: middle;
                    font-size: 10px;
                    background: #fff;

                    .o-calender-back-btn {
                        display: block;
                        position: absolute;
                        top: 2px;
                        left: 7px;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        border: 1px solid $weak-gray;

                        &:before {
                            content: "";
                            display: block;
                            position: absolute;
                            top: 7px;
                            left: 8px;
                            width: 8px;
                            height: 8px;
                            border-top: 2px solid $weak-gray;
                            border-right: 2px solid $weak-gray;
                            transform: rotate(225deg);
                        }
                    }

                    .o-calender-next-btn {
                        @extend .o-calender-back-btn;
                        left: 6px;

                        &:before {
                            transform: rotate(45deg);
                            left: 6px;
                        }
                    }

                    .o-calender-year-month {
                        display: block;
                        font-weight: 700;
                        text-align: center;
                    }
                }
            }
        }

        tbody {
            padding: 2px 0 0 0;
            border-top: 1px dotted $border-color;

            tr {
                th {
                    color: $base-text-color;
                    // 土曜日
                    &:nth-child(6) {
                        color: $strong-blue;
                    }

                    // 日曜日
                    &:nth-child(7) {
                        color: $strong-red;
                    }
                }

                td {
                    position: relative;
                    // バツ印ボタン（日付指定解除用）
                    > .a-close-btn-box {
                        display: block;
                        width: 100%;
                        height: 45px;

                        // 日付指定解除のボタン中央寄せ
                        .a-close-btn {
                            position: absolute;
                            top: 50%;
                            right: 0;
                            left: 0;
                            transform: translateY(-50%);
                            margin: 0 auto;
                        }
                    }

                    .o-calender-date {
                        display: block;
                        width: 100%;
                        height: 45px;
                        // padding: 12px 0;
                        font-size: 13px;
                        text-align: center;
                        vertical-align: middle;
                        line-height: 45px;
                        background: $calender-bg-color;
                        color: $border-color;

                        &--on {
                            @extend .o-calender-date;
                            outline: 1px solid $strong-red;
                            background: #fff;
                        }

                        .o-calender-date-link {
                            display: block;
                            text-decoration: underline;
                            font-weight: 700;
                        }
                    }

                    &:nth-child(6) > .o-calender-date {
                        background: $saturday-bg-color;
                    }

                    &:nth-child(7) > .o-calender-date {
                        background: $sunday-bg-color;
                    }
                }
            }
        }
    }
}