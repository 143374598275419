@charset "utf-8";
// 話読み用見出し

// ==============================================
// 読み込み
// ==============================================
@import "../colors";
@import "../../templates/layout/ttsk_mixin";

// ==============================================
// スタイル
// ==============================================
// アイコン付見出し
.a-ttsk-title--icon {
	position: relative;
	color: $ttsk-text-color;
	font-size: 12px;
	font-weight: bold;
	line-height: 1.5;
	margin: 0 16px;
	@include mq-up('md'){
		font-size: 22px;
	}

	&::before {
		content: '';
		width: 14px;
		height: 13px;
		position: absolute;
		top: -9px;
		left: -8px;
		background: url("/anne/img/ttsk/icon-title-colorful.svg") center left no-repeat;
		background-size: contain;
		@include mq-up('md'){
			width: 18px;
			height: 17px;
		}
	}
	
	.a-ttsk-title--icon__text-small {
		font-size: 14px;
	}
}

// 下線グレー見出し
.a-ttsk-title--border-gray {
	color: $ttsk-blue-gray-color;
	font-size: 12px;
	font-weight: bold;
	line-height: 1.2;
	padding-bottom: 8px;
	border-bottom: 1px solid $ttsk-blue-gray-color;
	@include mq-up('md'){
		font-size: 14px;
		line-height: 1.2;
	}
}

// 件数
.a-ttsk-title__count {
	font-size: 10px;
	font-weight: normal;
	@include mq-up('md'){
		font-size: 12px;
	}
}

// リンク
.a-ttsk-title__link {
	padding: 4px 8px;
	font-size: 12px;
	font-weight: bold;
	color: $ttsk-dark-blue-color;
	&:hover {
		cursor: pointer;
		@include mq-up('md'){
			@include textHover;
		}
	}
}