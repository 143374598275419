@charset "utf-8";
// ラベル

// ==============================================
// 読み込み
// ==============================================
@import "../../templates/layout/ttsk_mixin";
@import "../z-index";
// ==============================================
// スタイル
// ==============================================
@mixin a-ttsk-label-base {
    position: absolute;
}

.a-ttsk-label-logo {
    @include a-ttsk-label-base;
    left: 2px;
    bottom: 1px;
    width: 28%;
    padding-top: 25.638%;
    background: url(/anne/img/ttsk/logo-ttsk.svg) no-repeat 0 0;
    background-size: 100% auto;
    z-index: $ttsk-label-z-index;
    color-scheme: only light;
    @include mq-up('md') {
        left: 4px;
        bottom: 2px;
        width: 24%;
        padding-top: 21.975%;
    }
}