@charset "UTF-8";
@import "../colors";
@import "../z-index";

// テキスト付のトグルボタン
// テキスト部分のタップでもON・OFF切り替え
.m-toggle-btn-with-txt {
    display: inline-block;
    width: 100%;
    height: 28px;
    vertical-align: middle;

    input {
        display: none;
    }

    .a-toggle-btn-with-txt__txt {
        display: inline-block;
        float: left;
        height: 28px;
        line-height: 28px;
        font-size: 12px;
        font-weight: bold;
        color: $base-color;
    }

    // トグルボタン全体
    .a-toggle-btn-with-txt__txt {
        display: inline-block;
        position: relative;
        width: 100%;
        height: 28px;
        text-align: left;

        // OFFの時・テキスト部分
        &::before {
            content: attr(data-off-label);
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 80px;
            height: 28px;
            padding: 0 8px 0 0;
            font-weight: 700;
            border-radius: 20px;
            font-size: 14px;
            text-align: right;
            color: #fff;
            background: $weak-gray;
        }

        // OFFの時・ボタン部分
        &::after {
            content: '';
            position: absolute;
            top: 3px;
            right: 55px;
            width: 22px;
            height: 22px;
            background: #fff;
            border-radius: 50%;
            font-weight: 700;
            transform: translateX(0);
            //transitionするプロパティを増やすとsafe-search.jsが上手く発火しなくなるので注意#44755
            transition: transform 0.15s;
        }
    }

    // ONの時・背景色
    .a-toggle-btn-with-txt__input:checked + .a-toggle-btn-with-txt__txt {

        // ONの時・テキスト部分
        &::before {
            content: attr(data-on-label);
            right: 0;
            padding: 0 0 0 8px;
            text-align: left;
            color: #fff;
            background: $active-color;
        }

        // ONの時・ボタン部分
        &::after {
            //right: 4px;
            background: #fff;
            transform: translateX(52px);
        }
    }
}