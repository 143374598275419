@charset "utf-8";

// 読み込み
@import "../../atoms/colors";

// パンくず
.o-breadcrumb {
    width: 100%;
    height: 24px;
    overflow: hidden;
    white-space: nowrap;
    background: #fff;
}

.o-breadcrumb__list {
    // スクロールバー分をはみ出させてバーを隠す
    padding: 0 0 17px 0;
    overflow-x: scroll;

    li {
        display: inline-block;
        height: 24px;
        padding: 0 8px 0 13px;
        background: #fff;
        font-size: 11px;
        font-weight: 700;
        line-height: 24px;

        a {
            display: block;
            position: relative;

            // ここがパンくずの要素毎の区切りの > を作る
            &::before {
                content: "";
                position: absolute;
                z-index: 10;
                top: -6px;
                right: -15px;
                border-top: 17px solid transparent;
                border-bottom: 17px solid transparent;
                border-left: 11px solid #fff;
            }

            &::after {
                content: "";
                position: absolute;
                z-index: 5;
                top: -6px;
                right: -18px;
                width: 0;
                height: 0;
                border-top: 17px solid transparent;
                border-bottom: 17px solid transparent;
                border-left: 11px solid $background-color;
                border-right: 0 solid transparent;
            }
            // ここまで > を作る
        }

        &:first-child {
            padding: 0 8px;
            font-size: 0;

            // ホームマーク
            .a-svg-icon-home {
                width: 16px;
                height: auto;
                margin: 0 4px 0 8px;
                vertical-align: top;
                fill: $base-color;
            }

            a {
                // 最初の要素だけ高さ違うので>をずらす
                &::before {
                    top: -9px;
                }

                &::after {
                    top: -9px;
                }
                // ここまで
            }
        }

        // 最後のリンクのみ文字色：弱調・灰、矢印非表示
        &:last-child {
            a {
                color: $weak-gray;

                &::before {
                    display: none;
                }

                &::after {
                    display: none;
                }
            }
        }
    }
}
