// 購入モーダル、読了後モーダルの真ん中部分
// コイン、ポイント、チケットの情報
// （モーダル・読了後で読み込み）

// ==============================================
// 読み込み
// ==============================================
@import "../../atoms/buttons/ttsk-btn";
@import "../../atoms/colors";
@import "../../atoms/z-index";
@import "ttsk-check-list";
@import "ttsk-ticket-item";
@import "ttsk-coin-point-item";

// ==============================================
// スタイル
// ==============================================
// ticket-infoを囲うwrap
.m-ttsk-ticket-info__box,
%m-ttsk-ticket-info__box {
    border: 1px solid $ttsk-light-blue-gray-02-color;
    width: calc(100% - 28px);
    max-width: 500px;
    padding: 8px 10px 10px;
    margin: 0 auto;
    @include mq-up('md') {
        padding: 10px 16px 16px;
    }
}

// 必要コイン（チケット）数
.m-ttsk-ticket-info__required-text,
%m-ttsk-ticket-info__required-text {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $ttsk-border-color;
    font-size: 12px;
    font-weight: bold;
    padding: 0 14px 8px;
    line-height: 1.5;
    @include mq-up('md'){
        font-size: 18px;
        padding: 0 32px 10px;
    }
    // コイン（基本のスタイルの上書き）
    .m-ttsk-coin-item {
        display: inline-flex;
        align-items: stretch;
        font-size: 12px;
        margin-left: 12px;
        @include mq-up('md') {
            font-size: 18px;
            margin-left: 15px;
        }
        .image-coin {
            width: 13px;
            margin-right: 2px;
            @include mq-up('md') {
                width: 20px;
                margin-right: 4px;
            }
        }
    }
    // チケット
    .m-ttsk-ticket-item {
        display: inline-flex;
        font-size: 10px;
        margin-left: 2px;
        @include mq-up('md'){
            font-size: 15px;
        }
        .image-ticket {
            width: 20px;
            margin-left: 1px;
            @include mq-up('md'){
                width: 30px;
            }
        }
    }
}

.m-ttsk-ticket-info__error,
%m-ttsk-ticket-info__error {
    margin-top: 10px;
    @include mq-up('md') {
        margin-top: 16px;
    }
}

// 特定商取引法の改正に伴う注意書き
.m-ttsk-ticket-info__confirm-text,
%m-ttsk-ticket-info__confirm-text {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    @include mq-up('md') {
        margin-top: 16px;
    }
    .m-ttsk-check-list {
        @include mq-up('md') {
            margin-left: 17px;
        }
        li {
            @include mq-up('md') {
                font-size: 14px;
            }
            &::before {
                @include mq-up('md') {
                    left: -17px;
                    font-size: 14px;
                }
            }
        }
    }
}
