// 元PCサイドカラムの静的リンク集用
// Bob等々と共有のパーツ使うので、ここはAtomic Design化不能
@import "../../../../../../asset/bob/scss/sp/module/common/beginnersGuide";
@import "../../../../../../asset/anne/scss/sp/atoms/colors";

// menu-sp.scssから必要そうなものを持って来た
.beginnersGuide {
    background: #fff;
}

.bw_hdl-simple {
    padding: 0 10px;
    background: #1d3850;
    color: #fff;
    line-height: 38px;
    box-sizing: border-box;
}

.bw_list-simple {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;

    li {
        flex-basis: 50%;
        border-top: 1px solid $border-color;
        box-sizing: border-box;

        &:nth-child(-n+2) {
            border-top: none;
        }

        &:nth-child(2n) {
            border-left: 1px solid $border-color;
        }

        &:nth-child(2n+1):last-child {
            flex-basis: 100%;
        }
    }
}

.bw_list-simple li a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: start;
    box-sizing: border-box;
    height: 100%;
    min-height: 44px;
    line-height: 1.3;
    padding: 12px 30px 12px 12px;
    word-break: break-all;
}

.bw_list-simple li a:after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    width: 6px;
    height: 6px;
    border-right: 2px solid $arrow-color;
    border-bottom: 2px solid $arrow-color;
    transform: rotate(-45deg) translateY(-50%);
}
