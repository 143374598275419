// SP共通レイアウト 館ナビゲーション
@import "../../atoms/colors";

.o-store-nav {
    display: block;
    width: 100%;
    height: 53px;
    overflow: hidden;
    background-color: $background-color;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        display: none;
    }
}

.o-store-nav-tab {
    margin: 0;
    padding: 5px 5px 8px 0;
    line-height: 1.5;
    display: flex;

    &::-webkit-scrollbar {
        display: none;
    }
}

.o-store-nav-tab li {
    float: left;
    margin-left: 6px;
    flex-grow: 1;
    flex-shrink: 0;
}

.o-store-nav-cell {
    min-width: 80px;
    width: 80px;
    height: 42px;
    font-size: 82%;
    text-align: center;
    font-weight: bold;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    border-top: 1px solid $border-color;
    border-radius: 5px 5px 0 0;

    &.o-store-nav-cell--on {
        border-left: none;
        border-right: none;
        border-top: none;
    }
}

.o-store-nav-cell--sbsc {
  background-color: #fff;
  border-bottom: solid 2px $sbsc-key-color;
}

.o-store-nav-cell--free {
    background-color: #fff;
    border-bottom: solid 2px $free-key-color;

    &.o-store-nav-cell--on {
        background-color: $free-key-color;
        border-bottom: solid 2px $free-key-color;

        .ico-Zero-Books {
            color: #fff;
        }
    }

    .ico-Zero-Books {
        font-size: 20px;
        margin-right: 5px;
        color: $free-key-color;
        vertical-align: text-top;
        position: relative;
        top: -5px;
    }
}

.o-store-nav-cell--top {
    background-color: #fff;
    border-bottom: solid 2px $base-color;
    
    &.o-store-nav-cell--on {
        background-color: $base-color;
        border-bottom: solid 2px $base-color;
    }
}

.o-store-nav-cell--manga {
    background-color: #fff;
    border-bottom: solid 2px #0094d6;

    &.o-store-nav-cell--on {
        background-color: #0094d6;
        border-bottom: solid 2px #0094d6;
    }
}

.o-store-nav-cell--literature {
    background-color: #fff;
    border-bottom: solid 2px #5a1818;

    &.o-store-nav-cell--on {
        background-color: #5a1818;
        border-bottom: solid 2px #5a1818;
    }
}

.o-store-nav-cell--lightnovels {
    background-color: #fff;
    border-bottom: solid 2px $light-novel-key-color;

    &.o-store-nav-cell--on {
        background-color: $light-novel-key-color;
        border-bottom: solid 2px $light-novel-key-color;
    }
}

.o-store-nav-cell--bl {
    background-color: #fff;
    border-bottom: solid 2px $bl-key-color;

    &.o-store-nav-cell--on {
        background-color: #e84eaa;
        border-bottom: solid 2px #e84eaa;
    }
}

.o-store-nav-cell--tl {
    background-color: #fff;
    border-bottom: solid 2px $tl-key-color;

    &.o-store-nav-cell--on {
        background-color: $tl-key-color;
        border-bottom: solid 2px $tl-key-color;
    }
}

.o-store-nav-cell--otona {
    background-color: #fff;
    color: #000;
    border-bottom: solid 2px $r18-key-color;

    &.o-store-nav-cell--on {
        background-color: $r18-key-color;
        border-bottom: solid 2px $r18-key-color;
    }
}

.o-store-nav-cell--goods {
    background-color: #fff;
    color: #000;
    border-bottom: solid 2px #013e8a;

    &.o-store-nav-cell--on {
        background-color: #013e8a;
        border-bottom: solid 2px #013e8a;
    }
}

.o-store-nav-cell--indies {
    background-color: #fff;
    color: #000;
    border-bottom: solid 2px $indies-key-color;

    &.o-store-nav-cell--on {
        background-color: #83cbcf;
        border-bottom: solid 2px #83cbcf;
    }
}


.o-store-nav-cell-link {
    border-radius: 2px 2px 0 0;
    display: block;
    width: 100%;
    height: 100%;
    padding-top: 15px;
    color: $base-text-color;
}

.o-store-nav-cell--on .o-store-nav-cell-link {
    color: #fff;
}


.o-store-nav-cell--literature,
.o-store-nav-cell--lightnovels,
.o-store-nav-cell--indies {
    .o-store-nav-cell-link {
        padding-top: 5px;
    }
}
