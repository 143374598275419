@charset "utf-8";
// タテスク矢印アコーディオン制御ボタン
// TODO ヘッダーのセーフサーチのところ用にある程度調整しちゃってる

// ============================
// 読み込み
// ============================
@import "../colors";
@import "../ttsk/ttsk-arrow-mixin";
@import "../../templates/layout/ttsk_mixin";
@import "../../templates/layout/mixin";

// ==============================================
// スタイル
// ==============================================
// 上向き矢印・下向き矢印の開閉ボタン
.a-ttsk-up-down-arrow-btn {
    position: absolute;
    top: 50%;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
    right: -6px;
    &::before {
        @include baseTransition;
        @include ttsk-icon-arrow-down($color: $ttsk-blue-color);
        font-size: 24px;
        @include mq-up('md'){
            font-size: 24px;
        }
    }
    &.opened {
        &::before {
            transform: translateY(-50%) rotate(-180deg);
        }
    }
}