// SPヘッダ 話連載用左側ハンバーガーメニュー内分離

@import "../../../templates/layout/mixin";
@import '../../../templates/layout/ttsk_mixin';
@import "../../../atoms/colors";
@import "../../../atoms/ttsk/ttsk-icon";
@import "../../../atoms/z-index";
@import "../../../atoms/ttsk/ttsk-arrow-mixin";
@import '../../../molecules/ttsk/ttsk-login';
@import 'ttsk-header-left-menu-ticket-box';

@keyframes menuShowLeft {
    from {
        left: -100%;
    }
    to {
        left: 0;
    }
}

@keyframes menuHideLeft {
    from {
        left: 0;
    }
    to {
        left: -100%;
    }
}

.o-ttsk-header-menu {
    float: left;
}

.o-ttsk-header-menu-btn-wrapper {
    width: 44px;
    height: 44px;
    margin-right: 4px;
    @include mq-up('md') {
        margin-right: 6px;
    }
}

.o-ttsk-header-menu-content {
    padding: 48px 0 10px;
    left: -100%;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    overflow-y: auto;
    z-index: $header-content-z-index;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    @include mq-up('md') {
        max-width: 375px;
    }

    .o-ttsk-header-menu-content-top {
        position: fixed;
        padding: 4px;
        top: 0;
        left: -100%;
        height: 48px;
        width: 100%;
        z-index: $header-content-top-z-index;
        text-align: center;
        background: #fff;
        border-bottom: 1px solid #eef0f2;
        @include mq-up('md') {
            max-width: 375px;
        }
        .o-ttsk-header-menu-content-top__title {
            font-size: 16px;
            font-weight: bold;
            padding: 8px 0;
        }
        .o-ttsk-header-menu-content-top-R {
            margin: 0 3px 0;
            position: absolute;
            top: 2px;
            right: 10px;
        }
    }
    &.o-ttsk-header-menu-content--on {
        display: block;
        animation: menuShowLeft .2s 0s ease-in-out;
        animation-fill-mode: forwards;
        .o-ttsk-header-menu-content-top {
            display: block;
            animation: menuShowLeft .2s 0s ease-in-out;
            animation-fill-mode: forwards;
        }
    }
    &.o-ttsk-header-menu-content--off {
        animation: menuHideLeft .2s 0s ease-in-out;
        animation-fill-mode: forwards;
        .o-ttsk-header-menu-content-top {
            animation: menuHideLeft .2s 0s ease-in-out;
            animation-fill-mode: forwards;
        }

    }
}


// 会員登録・ログインエリア
.m-ttsk-header-left-menu-login {
    padding: 0 16px;
    margin: 24px 0 32px;
    .a-ttsk-btn {
        max-width: 500px;
        width: 100%;
        margin: 10px auto 0;
        @include mq-up('md') {
            margin-top: 15px;
        }
        @include mq-down('xs') {
            margin-top: 8px;
        }
    }
    .a-ttsk-btn--primary {
        font-size: 14px;
        padding: 14px 0;
    }
    .a-ttsk-btn--secondary {
        font-size: 14px;
        padding: 13px 0;
    }
    .m-ttsk-login__registration {
        .a-ttsk-text--annotation {
            margin-top: 10px;
            padding: 0;
        }
    }
}


// チケット購入ボタン
.o-ttsk-header-left-menu-ticket-purchase-btn {
    margin: 16px 16px 24px;
    .a-ttsk-btn--primary {
        font-size: 14px;
        padding: 14px 0;
    }
}

// 下部のリスト
.o-ttsk-header-menu-info-box, .o-ttsk-header-menu-bottom-box, .o-ttsk-header-menu-service-box{
    a {
        text-align: left;
        display: flex;
        align-items: center;
        color: $ttsk-dark-blue-color;
        padding: 12px 16px;
        &:hover {
            @include mq-up('md') {
                opacity: 1;
            }
            .ico-txt {
                @include mq-up('md') {
                    @include textHover;
                }
            }
        }
    }

    //li a:after {
    //    @include ttsk-icon-arrow-right($ttsk-blue-color);
    //}

    .ico-txt {
        font-size: 12px;
        padding-left: 8px;
        line-height: 1.5;
    }

    .a-ttsk-icon--store, .a-ttsk-icon--read, .a-ttsk-icon--info, .a-ttsk-icon--help, .a-ttsk-icon--logout, .ico-beginner {
        display: inline-block;
        color: $ttsk-blue-color;
        &::before {
            font-size: 14px;
            display: flex;
        }
    }
}

.o-ttsk-header-menu-info-box {
    border-top: 1px solid $ttsk-border-color;
    border-bottom: 1px solid $ttsk-border-color;
}

.o-ttsk-header-menu-app-box {
    border-top: 1px solid $ttsk-border-color;

    .o-ttsk-header-menu-app-item:not(:first-child) {
        border-top: 1px solid $ttsk-border-color;
    }

    .o-ttsk-header-menu-app-item a {
        position: relative;
        text-align: left;
        display: block;
        font-size: 15px;
        font-weight: bold;
        color: $ttsk-dark-blue-color;
        padding: 18px 44px 18px 16px;
        transition: 0.3s ease-in-out;

        &:after {
            @include ttsk-icon-arrow-right($ttsk-blue-color);
            font-size: 24px;
            right: 10px;
            @include mq-up('md') {
                font-size: 24px;
            }
        }
        &:hover {
            @include mq-up('md') {
                @include textHover;
            }
        }
    }
}


.o-ttsk-header-menu-service-head {
    color: $ttsk-dark-blue-color;
    font-size: 15px;
    font-weight: bold;
    padding: 18px 44px 8px 16px;
    border-top: 1px solid $ttsk-border-color;
}

.o-ttsk-header-menu-service-box {
    display: block;
    padding-bottom: 15px;
}

