@charset "utf-8";

@import "bob/fonts/style";
@import "font-variable";
@import "../../atoms/colors";

//  BODY
html {
    width: 100%;
    height: 100%;
    font-size: 62.5%; // font-size:10px
}

body {
    background: $background-color;
    color: $base-text-color;
    font-size: 1.3rem;
    font-family: $base-font-family;
    width: 100%;
    height: 100%;
    line-height: 1.5;
    overflow-x: hidden;
}

a {
    color: $base-link-color;
    text-decoration: none;
}

// iconfont resize
.ico {
    font-size: 20px;
}

// style.cssでbookwalker !importantになっている為、強制上書き
.ico-txt {
    font-family: $base-font-family !important;
}

.ico, .c-icf-txt {
    display: inline-block;
    vertical-align: baseline;
    line-height: 1;
}

img {
    color-scheme: only light;
}

// accessibility
.u-accessibility {
    visibility: hidden;
    height: 0;
}
