@charset "UTF-8";
// タテスクmixin

$sizes: xl, lg, md, sm, xs;

// responsive
$breakpoint-up: (
    'xs': 'screen and (min-width: 321px)',
    'sm': 'screen and (min-width: 376px)',
    'md': 'screen and (min-width: 768px)',
    'lg': 'screen and (min-width: 1111px)',
    'xl': 'screen and (min-width: 1201px)',
) !default;

// max-width
$breakpoint-down: (
    'xs': 'screen and (max-width: 320.98px)',
    'sm': 'screen and (max-width: 375.98px)',
    'md': 'screen and (max-width: 767.98px)',
    'lg': 'screen and (max-width: 1112.98px)',
    'xl': 'screen and (max-width: 1200.98px)',
) !default;

// @mixinの定義
@mixin mq-up($breakpoint: md) {
    @media #{map-get($breakpoint-up, $breakpoint)} {
        @content;
    }
}

@mixin mq-down($breakpoint: md) {
    @media #{map-get($breakpoint-down, $breakpoint)} {
        @content;
    }
}

// hover
@mixin hover {
    opacity: 0.7;
}

// textリンクhover underline
@mixin textHover {
    opacity: 1;
    text-decoration: underline;
}

// disabled
@mixin disabledColor {
    background-color: #ccc;
    color: #999;
}

// transition
@mixin baseTransition {
    transition: all 0.3s ease-in-out;
}