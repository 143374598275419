@charset "utf-8";
// 話読みセクション

// ============================
// 読み込み
// ============================
@import "../../templates/layout/ttsk_mixin";
@import "../../atoms/colors";
@import "../../atoms/z-index";
@import "../../atoms/ttsk/ttsk-arrow-mixin";

// ==============================================
// スタイル
// ==============================================
// セクション
// ==============================================
// セクション（あしらいなし）
.o-ttsk-section--simple {
    padding-top: 25px;
    @include mq-up('md'){
        padding-top: 30px;
    }
}

// セクション（あしらいあり）
@mixin ttsk-section-base {
    position: relative;
    width: 100%;
    background-color: #fff;
    color: $ttsk-text-color;
    margin-bottom: 45px;
    z-index: 1;
    @media screen and (min-width: 414.98px) {
        margin-bottom: 70px;
    }
    @include mq-up('md'){
        margin-bottom: 90px;
    }
    &::before,
    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 120%;
        height: 45px;
        @media screen and (min-width: 414.98px) {
            height: 70px;
        }
        @include mq-up('md'){
            height: 90px;
        }
    }
    &:before {
        background: #9ddffB;
        transform-origin: left center;
        transform: rotate(3deg);
        z-index: -1;
        @media screen and (min-width: 414.98px) {
            transform: rotate(2deg);
        }
        @include mq-up('xl'){
            transform: rotate(1deg);
        }
    }
    &:after {
        background: #e4f2fd;
        transform-origin: left center;
        transform: rotate(6deg);
        z-index: -2;
        @media screen and (min-width: 414.98px) {
            transform: rotate(4deg);
        }
        @include mq-up('xl'){
            transform: rotate(2deg);
        }
    }
}

// ブルー→イエロー→グリーン→レッドの順番
// o-ttsk-section__wrapで囲む
.o-ttsk-section__wrap {
    .o-ttsk-section {
        @include ttsk-section-base;
        &:first-child,
        &:nth-child(4n+1) {
            border-color: $ttsk-blue-color;
            &:before {
                background: #9ddffB;
            }
            &:after {
                background: #e4f2fd;
            }
            .o-ttsk-section__inner {
                border-color: $ttsk-blue-color;
            }
            .o-ttsk-section__title--multiple,
            .o-ttsk-section__title--simple-border,
            .o-ttsk-section__title {
                border-color: $ttsk-blue-color;
            }
        }
        &:nth-child(2),
        &:nth-child(4n+2) {
            border-color: $ttsk-yellow-color;
            &:before {
                background: #fbeb9d;
            }
            &:after {
                background: #fdf9e4;
            }
            .o-ttsk-section__inner {
                border-color: $ttsk-yellow-color;
            }
            .o-ttsk-section__title--multiple,
            .o-ttsk-section__title--simple-border,
            .o-ttsk-section__title {
                border-color: $ttsk-yellow-color;
            }
        }
        &:nth-child(3),
        &:nth-child(4n+3) {
            border-color: $ttsk-green-color;
            &:before {
                background: #d1ee95;
            }
            &:after {
                background: #f5fde4;
            }
            .o-ttsk-section__inner {
                border-color: $ttsk-green-color;
            }
            .o-ttsk-section__title--multiple,
            .o-ttsk-section__title--simple-border,
            .o-ttsk-section__title {
                border-color: $ttsk-green-color;
            }
        }
        &:nth-child(4),
        &:nth-child(4n+4) {
            border-color: $ttsk-red-color;
            &:before {
                background: #fbbf9d;
            }
            &:after {
                background: #fdede4;
            }
            .o-ttsk-section__inner {
                border-color: $ttsk-red-color;
            }
            .o-ttsk-section__title--multiple,
            .o-ttsk-section__title--simple-border,
            .o-ttsk-section__title {
                border-color: $ttsk-red-color;
            }
        }
    }
}

// ブルー
.o-ttsk-section--blue {
    @include ttsk-section-base;
    border-color: $ttsk-blue-color;
    &:before {
        background: #9ddffB;
    }
    &:after {
        background: #e4f2fd;
    }
    .o-ttsk-section__inner {
        border-color: $ttsk-blue-color;
    }
    .o-ttsk-section__title--multiple,
    .o-ttsk-section__title--simple-border,
    .o-ttsk-section__title {
        border-color: $ttsk-blue-color;
    }
}

// イエロー
.o-ttsk-section--yellow {
    @include ttsk-section-base;
    border-color: $ttsk-yellow-color;
    &:before {
        background: #fbeb9d;
    }
    &:after {
        background: #fdf9e4;
    }
    .o-ttsk-section__inner {
        border-color: $ttsk-yellow-color;
    }
    .o-ttsk-section__title--multiple,
    .o-ttsk-section__title--simple-border,
    .o-ttsk-section__title {
        border-color: $ttsk-yellow-color;
    }
}

// グリーン
.o-ttsk-section--green {
    @include ttsk-section-base;
    border-color: $ttsk-green-color;
    &:before {
        background: #d1ee95;
    }
    &:after {
        background: #f5fde4;
    }
    .o-ttsk-section__inner {
        border-color: $ttsk-green-color;
    }
    .o-ttsk-section__title--multiple,
    .o-ttsk-section__title--simple-border,
    .o-ttsk-section__title {
        border-color: $ttsk-green-color;
    }
}

// レッド
.o-ttsk-section--red {
    @include ttsk-section-base;
    border-color: $ttsk-red-color;
    &:before {
        background: #fbbf9d;
    }
    &:after {
        background: #fdede4;
    }
    .o-ttsk-section__inner {
        border-color: $ttsk-red-color;
    }
    .o-ttsk-section__title--multiple,
    .o-ttsk-section__title--simple-border,
    .o-ttsk-section__title {
        border-color: $ttsk-red-color;
    }
}


// 共通要素
// ==============================================
.o-ttsk-section__inner {
    background-color: #fff;
    padding-bottom: 30px;
    border-bottom: 4px solid;
    @include mq-up('md') {
        border-bottom: 6px solid;
    }
}

// 最大幅1080px
.o-ttsk-section__body {
    max-width: 1080px;
    margin: 0 auto;
    padding: 0 16px;
    @include mq-up('lg') {
        padding: 0;
    }
}

// 最大幅768px
.o-ttsk-section__body--narrow {
    max-width: 768px;
    margin: 0 auto;
    padding: 0 16px;
    @media screen and (min-width: 800px) {
        padding: 0;
    }
}

// 見出し
.o-ttsk-section__title, %o-ttsk-section__title {
    border-bottom: 4px solid;
    margin-bottom: 24px;
    padding-bottom: 5px;
    @include mq-up('md'){
        border-bottom: 6px solid;
    }
    .a-ttsk-title--icon {
        display: block;
        position: relative;
        font-size: 16px;
        line-height: 1.5;
        font-weight: bold;
        @include mq-up('md') {
            max-width: 1080px;
            font-size: 22px;
            line-height: 1.5;
        }
        @include mq-up('lg') {
            margin: 0 auto;
        }

        &::after {
            content: "";
            width: 32px;
            height: 4px;
            position: absolute;
            left: 0;
            bottom: -9px;
            background-image: linear-gradient(90deg, $ttsk-blue-color 0, $ttsk-blue-color 8px, $ttsk-yellow-color 8px, $ttsk-yellow-color 16px, $ttsk-green-color 16px, $ttsk-green-color 24px, $ttsk-red-color 24px, $ttsk-red-color 32px);
            background-repeat: no-repeat;
            @include mq-up('md') {
                width: 64px;
                height: 6px;
                bottom: -11px;
                background-image: linear-gradient(90deg, $ttsk-blue-color 0, $ttsk-blue-color 16px, $ttsk-yellow-color 16px, $ttsk-yellow-color 32px, $ttsk-green-color 32px, $ttsk-green-color 48px, $ttsk-red-color 48px, $ttsk-red-color 64px);
                background-repeat: no-repeat;
            }
        }
    }
}

// 見出し下線なし
.o-ttsk-section__title--no-border {
    max-width: 1080px;
    margin: 0 auto;

    .a-ttsk-title--icon {
        display: block;
        position: relative;
        font-size: 16px;
        line-height: 1.5;
        font-weight: bold;
        @include mq-up('md') {
            max-width: 1080px;
            font-size: 22px;
            line-height: 1.5;
        }
        @include mq-up('lg') {
            margin: 0 auto;
        }
    }
}

// 見出し下線 1px
.o-ttsk-section__title--simple-border {
    @extend %o-ttsk-section__title;
    border-bottom: 1px solid;

    .a-ttsk-title--icon {
        &::after {
            content: none;
        }
    }
}

// 見出し 複数要素
.o-ttsk-section__title--multiple {
    @extend %o-ttsk-section__title;
    .o-ttsk-section__title--multiple__inner {
        display: flex;
        align-items: flex-end;
        max-width: 1080px;
        margin: 0 16px;
        @include mq-up('lg') {
            margin: 0 auto;
        }
        @include mq-down('xs') {
            align-items: center;
        }
    }
    .a-ttsk-title--icon {
        margin: 0;
    }

    //右寄せ要素
    .o-ttsk-section__title--multiple__right {
        margin-left: auto;
    }
}

// もっと見るボタン
.a-ttsk-read-more-btn {
    width: calc(100% - 32px);
    margin-top: 20px;
    @include mq-up('md'){
        margin-top: 40px;
    }
}

// 折りたたみセクション
.o-ttsk-section-collapse {
    position: relative;
    overflow: hidden;

    &::after {
        content: '';
        width: calc(100% - 32px);
        height: 40px;
        position: absolute;
        right: 16px;
        bottom: 0;
        background: linear-gradient(180deg,rgba(255,255,255,.2) 0,rgba(255,255,255,.8) 40%,#fff 100%);
        @include mq-up('md'){
            width: 100%;
            height: 50px;
            right: 0;
            background: linear-gradient(180deg,rgba(255,255,255,.2) 0,rgba(255,255,255,.8) 30%,#fff 100%);
        }
    }
    // オープン
    &.js-section-collapse--show {
        max-height: 100%;
        transition:
            max-height 0.3s ease-in,
            opacity  0.1s linear 0.1s,
            visibility  0.1s linear 0.1s;
        &::after {
            content: none;
        }

        .o-ttsk-section-collapse__btn {
            display: none;
            visibility: hidden;
            pointer-events: none;
        }
    }
}

// 折りたたみセクションボタン
.o-ttsk-section-collapse__btn {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    max-width: 100%;
    width: calc(100% - 32px);
    height: 80px;
    position: absolute;
    right: 16px;
    bottom: 0;
    color: $ttsk-blue-gray-color;
    font-size: 12px;
    font-weight: bold;
    text-align: right;
    z-index: 10;
    @include mq-up('md'){
        width: 100%;
        font-size: 14px;
        right: 3px;
    }
    span {
        display: block;
        width: 100%;
        position: relative;
        background-color: #fff;
        padding-right: 14px;
        @include mq-up('md'){
            padding-right: 19px;
        }
        // アイコン読み込み
        &::after {
            @include ttsk-icon-arrow-down($ttsk-blue-gray-color);
        }
    }
    &:hover {
        @include mq-up('md'){
            opacity: 1;
            span {
                @include textHover;
            }
        }
    }
}


